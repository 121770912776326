import React from 'react';

import { Avatar, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { SettingOutlined, FileSearchOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../stores/token';
import { setModules } from '../stores/modules'
import { setLangCode } from '../stores/langCode';

const HeaderBar = (props) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.user);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let { langCms } = useSelector((state) => state.langCms);

  let logout = (props) => {
    Cookies.remove('token');
    window.localStorage.clear();
    dispatch(setToken(null));
    dispatch(setModules([]));
    dispatch(setLangCode(langCms && langCms[0] && langCms[0].code));
  };
  const menu = (
    <Menu className="head-menu">
      <Menu.Item>
        <a rel="noopener noreferrer" href="./adminInfo">
          {' '}
          {languagesCms ? languagesCms.ADMIN_INFORMATION : null}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout} danger>
        {languagesCms ? languagesCms.LOG_OUT : null}
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="topMenu">
      <div>
        {user && user.role === 'superAdmin' && (
          <Link to="/Logs">
            <FileSearchOutlined className="logs" />
          </Link>
        )}
      </div>
      <div>
        {user && user.role === 'superAdmin' && (
          <Link to="/settings">
            <SettingOutlined className="settings" />
          </Link>
        )}
      </div>

      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <div>
          <Avatar
            onClick={(e) => e.preventDefault()}
            className="ant-dropdown-link"
            src="/images/default-avatar.svg"
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default HeaderBar;
