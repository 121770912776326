import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Card,
  message,
  Select,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  CarBrandPicker,
  FileInput,
  EstateTypePicker,
  EstateStatusPicker,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function disabledDate(current) {
  return current && current > moment().endOf('year');
}

const SecondhandDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let { langCode } = useSelector((state) => state.langCode);

  let newRecord = {
    name: '',
    content: '',
    active: true,
    pinned: true,
    type: 'other',
    price: '',
    phone: null,
    city: null,
    country: null,
    medias: [],
  };

  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/secondhand/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.phone === null) errors.phone = languagesCms.ENFORCED;

    if (data.content === null || data.content.length === 0)
      errors.content = languagesCms.ENFORCED;

    if (data.price === null || data.price.length === 0)
      errors.price = languagesCms.ENFORCED;

    if (data.country === null || data.country.length === 0)
      errors.country = languagesCms.ENFORCED;

    if (data.type === null) errors.type = languagesCms.ENFORCED;

    if (data.active === null) errors.active = languagesCms.ENFORCED;

    if (data.city === null) errors.city = languagesCms.ENFORCED;

    if (data.medias.length === 0) errors.medias = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/secondhand/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/secondhand');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/secondhand', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/secondhand');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const { Option } = Select;
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/secondhand">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              {id && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.ADVERTISER}
                    help={errors.createdByName}
                    validateStatus={errors.createdByName ? 'error' : 'success'}
                  >
                    <Typography.Text strong>
                      {data.createdByName}
                    </Typography.Text>
                  </Form.Item>
                </Col>
              )}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PHONE}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <Input
                    name="phone"
                    value={data.phone}
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PIN}
                  required
                  help={errors.pinned}
                  validateStatus={errors.pinned ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.pinned ? true : false}
                    checkedChildren={languagesCms.YES}
                    unCheckedChildren={languagesCms.NO}
                    onChange={(v) => setData({ ...data, pinned: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label={languagesCms.EXPLANATION}
                  required
                  help={errors.content}
                  validateStatus={errors.content ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="content"
                    value={data.content}
                    onChange={(v) =>
                      setData({ ...data, content: v.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.COUNTRY}
                  required
                  help={errors.country}
                  validateStatus={errors.country ? 'error' : 'success'}
                >
                  <Input
                    name="country"
                    value={data.country}
                    onChange={(e) =>
                      setData({ ...data, country: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CITY}
                  required
                  help={errors.city}
                  validateStatus={errors.city ? 'error' : 'success'}
                >
                  <Input
                    name="city"
                    value={data.city}
                    onChange={(e) => setData({ ...data, city: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PRICE}
                  required
                  help={errors.price}
                  validateStatus={errors.price ? 'error' : 'success'}
                >
                  <Input
                    name="price"
                    value={data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TYPE}
                  required
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.type ? data.type : 'other'}
                    style={{ width: 250 }}
                    onChange={(v) => {
                      setData({ ...data, type: v });
                    }}
                  >
                    <Option value="other">{languagesCms.OTHER}</Option>
                    <Option value="estate">{languagesCms.ESTATE}</Option>
                    <Option value="car">{languagesCms.CAR}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              {data.type === 'car' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.CAR_BRAND}
                    required
                    help={errors.carBrand}
                    validateStatus={errors.carBrand ? 'error' : 'success'}
                  >
                    <CarBrandPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="carBrand"
                      mode="single"
                    />
                  </Form.Item>
                </Col>
              )}
              {data.type === 'car' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.CAR_MODEL_YEAR}
                    required
                    help={errors.carModelYear}
                    validateStatus={errors.carModelYear ? 'error' : 'success'}
                  >
                    <DatePicker
                      defaultValue={() => moment(data.carModelYear)}
                      onChange={(v) => setData({ ...data, carModelYear: v })}
                      picker="year"
                      format="YYYY"
                      showTime={true}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row direction="row">
              {data.type === 'car' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.CAR_KILOMETER}
                    required
                    help={errors.carKm}
                    validateStatus={errors.carKm ? 'error' : 'success'}
                  >
                    <Input
                      name="carKm"
                      addonAfter="KM"
                      value={data.carKm}
                      onChange={(e) =>
                        setData({ ...data, carKm: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row direction="row">
              {data.type === 'estate' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.PROPERTY_TYPE}
                    required
                    help={errors.estateType}
                    validateStatus={errors.estateType ? 'error' : 'success'}
                  >
                    <EstateTypePicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="estateType"
                      mode="single"
                      lang={langCode}
                    />
                  </Form.Item>
                </Col>
              )}

              {data.type === 'estate' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.ESTATE_STATUS}
                    required
                    help={errors.estateStatus}
                    validateStatus={errors.estateStatus ? 'error' : 'success'}
                  >
                    <EstateStatusPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="estateStatus"
                      mode="single"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row direction="row">
              {data.type === 'estate' && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.ESTATE_M2}
                    required
                    help={errors.estateM2}
                    validateStatus={errors.estateM2 ? 'error' : 'success'}
                  >
                    <Input
                      name="estateM2"
                      addonAfter="m^2"
                      value={data.estateM2}
                      onChange={(e) =>
                        setData({ ...data, estateM2: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row direction="row">
              {data.type === 'other' && (
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label={languagesCms.BRAND_MODEL_INFO}
                    help={errors.productName}
                    validateStatus={errors.productName ? 'error' : 'success'}
                  >
                    <Input.TextArea
                      name="productName"
                      value={data.productName}
                      onChange={(v) =>
                        setData({ ...data, productName: v.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label={languagesCms.MEDIA}
                  help={errors.medias}
                  validateStatus={errors.medias ? 'error' : 'success'}
                >
                  <FileInput
                    languagesCms={languagesCms}
                    name="medias"
                    title="Medyalar"
                    type="file"
                    multi={true}
                    ext={['image', 'video']}
                    record={data}
                    setRecord={setData}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SecondhandDetail;
