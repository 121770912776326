import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { LikeOutlined, ExclamationOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useSelector } from 'react-redux';

import {
  DeleteOutlined,
  LeftOutlined,
  FileTextOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

const SurveysWithMediaAnswers = (props) => {
  let params = useParams();
  let query = [];
  let id = params.id !== 'add' ? params.id : false;
  query['survey_id'] = id;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  const { isSmall } = useSelector((state) => state.isSmall);

  let [search, setSearch] = useState(false);
  const { Search } = Input;
  let [loading, setLoading] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-created';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';

      let restData = await api
        .get(
          `/rest/surveyWithMediaAnswers/getNameAnswer?${qs.stringify(_params)}`,
          { _params },
        )
        .then(({ data }) => {
          if (data.result) {
            setLoading(false);
            setTotalCount(data.result.length);
            return data.result.map((item, key) => {
              if (item.active) item.active = <CheckOutlined />;
              else item.active = <CloseOutlined />;

              item.key = key;

              return item;
            });
          }
        });

      setData(restData);
    } else {
      let restData = await api
        .get(`/rest/surveyWithMediaAnswers?${qs.stringify(_params)}`, {
          _params,
        })
        .then(({ data }) => {
          if (data.result) {
            setLoading(false);
            setTotalCount(data.result.total);
            return data.result.rows.map((item, key) => {
              if (item.active) item.active = <CheckOutlined />;
              else item.active = <CloseOutlined />;
              item.key = key;
              return item;
            });
          }
        });
      setData(restData);
    }
  };
  useEffect(() => {
    get();
  }, [module]);

  useEffect(() => {
    if (modules) {
      setLoading(true);
    }
  }, []);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/surveyWithMediaAnswers/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/surveyWithMediaAnswers/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.USER,
      dataIndex: 'attendee_id',
      key: 'attendee_id',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
      render: (user) =>
        user && [user.name, user.lastname].filter((x) => x).join(' '),
    },
    {
      title: languagesCms.COMPLETED,
      dataIndex: 'completed',
      key: '_id',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Space size="small">
          {record.completed === true ? (
            <span style={{ fontSize: '1.25rem', color: '#008000' }}>
              <LikeOutlined />
            </span>
          ) : (
            <span style={{ fontSize: '1.25rem', color: 'red' }}>
              <ExclamationOutlined />
            </span>
          )}
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/surveysWithMedia/' + id + '/detail/' + record._id}>
            <Button icon={<FileTextOutlined />}>
              {isSmall ? '' : languagesCms.VIEW}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}

          <Link to={'/surveysWithMedia'}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {isSmall ? '' : languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <div style={{ borderColor: 'red' }}>
          <Table
            dataSource={data}
            columns={columns}
            loading={{
              spinning: loading,
              indicator: <Loading3QuartersOutlined spin />,
              size: 'large',
            }}
            onChange={handleTableChange}
            pagination={{
              total: totalCount,
            }}
            rowSelection={{ ...rowSelection }}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveysWithMediaAnswers;
