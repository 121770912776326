import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: null,
};

const modules = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
  },
});

export const { setModules } = modules.actions;
export default modules.reducer;
