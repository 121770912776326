import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, InputNumber, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { FileInputSingle, ModulePicker, LangPicker, ItemByModulePicker,GroupPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import { useSelector } from "react-redux";


const BannerDetail = (props) => {
  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let {langCode} = useSelector((state) => state.langCode);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let { langCms } = useSelector((state) => state.langCms);

  let nowDate = new Date();
  let newRecord = {
    lang: langCode ? langCode : langCms? langCms[0].code : "",
    title: '',
    item_id: null,
    active: true,
    order: 0,
    startDate: new Date(),
    groups:[],
    endDate: nowDate.setDate(nowDate.getDate() + 30)
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [moduleChange, setModuleChange] = useState(false);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, ] = useState(false)

  let { modules } = useSelector((state) => state.modules);
  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
      api.get("/rest/banners/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang == null)
      errors.lang = languagesCms.ENFORCED

    if (data.order == null)
      errors.order = languagesCms.ENFORCED

    if (data.media == null || data.media === {})
      errors.media = languagesCms.ENFORCED

    if (data.moduleId == null)
      errors.moduleId = languagesCms.ENFORCED

    if (data.startDate == null)
      errors.startDate = languagesCms.ENFORCED

    if (data.endDate == null)
      errors.endDate = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);


  let save = async () => {
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        api.put("/rest/banners/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/banners')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/banners", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/banners')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/banners">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.NAME} help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                  <Input name="title" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.GROUP} help={errors.groups}
                                           validateStatus={errors.active ? 'error' : 'success'}>
                                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups"/>
                                </Form.Item>
                            </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.OPEN_MODULE} required help={errors.moduleId} validateStatus={errors.moduleId ? 'error' : 'success'}>
                  <ModulePicker languagesCms={languagesCms} langCode={langCode} langCms={langCms} record={data} setRecord={setData} moduleChange={moduleChange} setModuleChange={setModuleChange} name="moduleId" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.SHARING} required help={errors.item_id} validateStatus={errors.moduleId ? 'error' : 'success'}>
                  <ItemByModulePicker languagesCms={languagesCms} record={data} setRecord={setData} name="item_id" moduleChange={moduleChange}
                    disabled={data.moduleId === "not_modul" || data.moduleId === "notifications" || !data.moduleId ? true : false} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.ORDER} help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                  <InputNumber value={data.order} min={0} defaultValue={0} style={{ width: 100 }} onChange={v => setData({ ...data, order: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.START_DATE} required help={errors.startDate} validateStatus={errors.startDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.startDate)} onChange={v => setData({ ...data, startDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.END_DATE} required help={errors.endDate} validateStatus={errors.endDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.endDate)} onChange={v => setData({ ...data, endDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                  <Alert message={languagesCms.DEFAULT_30_DAYS} banner />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.MEDIA} required help={errors.media} validateStatus={errors.media ? 'error' : 'success'}>
                  <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="media" />
                  <Alert message={languagesCms.BANNER_PIXEL_MESSAGE} banner />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};



export default BannerDetail;
