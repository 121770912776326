import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdfIcon: null,
};

const pdfIcon = createSlice({
  name: 'pdfIcon',
  initialState,
  reducers: {
    setPdfIcon: (state, action) => {
      state.pdfIcon = action.payload;
    },
  },
});

export const { setPdfIcon } = pdfIcon.actions;
export default pdfIcon.reducer;
