import React, { useState, useEffect } from 'react';
import { Upload, message, Progress } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api from '../service/index';

export default (props) => {
  let { record, setRecord, name, pop } = props;
  let [files, setFiles] = useState([]);
  let [listFiles, setListFiles] = useState([]);
  let [, setLoading] = useState(false);
  let [uploadStart, setUploadStart] = useState(false);
  const [progress, setProgress] = useState(0);
  let languagesCms = props.languagesCms;
  useEffect(() => {
    setListFiles(convertToUploadFiles(record[name]));
    setFiles(record[name]);
  }, []);

  let startUploadingFiles = (fileList) => {
    let newList = fileList.map((item, index) => {
      return {
        uid: index,
        name: '',
        status: item._id ? 'done' : 'uploading',
        url: item.url || '',
      };
    });
    return newList;
  };

  let convertToUploadFiles = (fileList) => {
    let newList = fileList.map((item, index) => {
      return { uid: index, name: '', status: 'done', url: item.url };
    });
    return newList;
  };

  /*let remove = item => {
		setRecord({ ...record, [name]: record[name].filter(x => x._id !== item.file.uid) });

	};*/

  let handleChange = (info) => {
    if (info) {
      if (!info.event && uploadStart === false) {
        if (info.file.status === 'removed') {
          let restFiles = files;
          let newFiles = restFiles.filter((item) => item.url !== info.file.url);
          setRecord({ ...record, [name]: newFiles });
          setListFiles(convertToUploadFiles(newFiles));
          setFiles(newFiles);
        } else {
          setUploadStart(true);
          const formData = new FormData();
          formData.append('files_0', info.file.originFileObj);
          if (info.file.originFileObj.size > 56844918)
            message.warning(languagesCms.WARNING_FILE_SIZE);
          else {
            setListFiles(startUploadingFiles([...files, { url: '' }]));
            api
              .post('/rest/upload', formData, {
                headers: { ContenType: 'multipart/form-data' },
                onUploadProgress: (event) => {
                  const percent = Math.floor(
                    (event.loaded / event.total) * 100,
                  );
                  setProgress(percent - 1);
                },
              })
              .then(({ data: { result, result_message } }) => {
                setTimeout(() => setProgress(100), 1000);
                setTimeout(() => setProgress(0), 2000);
                if (result_message.type === 'success') {
                  let restFiles = files;
                  if (pop) restFiles = [result[0]].concat(restFiles);
                  else restFiles.push(result[0]);
                  setRecord({ ...record, [name]: restFiles });

                  setListFiles(convertToUploadFiles(restFiles));
                }
                setUploadStart(false);
              });
          }
        }
      }
    }

    setLoading(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={listFiles}
        onChange={handleChange}
        multiple
      >
        {/* {listFiles.length >= 20 ? null : uploadButton} */}
        {uploadButton}
      </Upload>
      {progress > 0 ? (
        <Progress style={{ width: '60%' }} percent={progress} />
      ) : null}
    </>
  );
};
