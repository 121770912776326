import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  languagesCms: null,
};

const languagesCms = createSlice({
  name: 'languagesCms',
  initialState,
  reducers: {
    setLanguagesCms: (state, action) => {
      state.languagesCms = action.payload;
    },
  },
});

export const { setLanguagesCms } = languagesCms.actions;
export default languagesCms.reducer;
