import { configureStore } from '@reduxjs/toolkit';

import customization from './customization';
import settings from './settings';
import isSmall from './isSmall';
import collapsed from './collapsed';
import user from './user';
import token from './token';
import langCode from './langCode';
import languagesCms from './languagesCms';
import modules from './modules';
import logoEn from './logoEn';
import langCms from './langCms';
import langs from './langs';
import logo from './logo';
import pdfIcon from './pdfIcon';
import loginSetting from './loginSetting';

const store = configureStore({
  reducer: {
    customization,
    settings,
    isSmall,
    collapsed,
    user,
    token,
    langCode,
    languagesCms,
    modules,
    logoEn,
    langCms,
    langs,
    logo,
    pdfIcon,
    loginSetting,
  },
});

export default store;
