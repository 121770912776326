import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  message,
  Select,
  Switch,
  Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  GroupPicker,
  SendNotificationInput,
  LegislationPicker,
} from '../components';

import api from '../service/index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useCallback } from 'react';

const EmailSenderDetail = (props) => {
  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);

  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let { langCms } = useSelector((state) => state.langCms);
  let {langCode} = useSelector((state) => state.langCode);

  let newRecord = {
    lang: langCode ? langCode : langCms && langCms[0] && langCms[0].code ? langCms[0].code : 'TR',
    groups: [],
    sendNotification: false,
    sendEmail: false,
    sendGroupEmail: false,
  };

  let [disabled, setDisabled] = useState(false);

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

   
  useEffect(() => {
    if (id) {
      api
        .get('/rest/emailSender/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          if (result.sendEmail) {
            setDisabled(true);
          }
          if (result.sendGroupEmail) {
            setDisabled(true);
          }
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.type === null || data.type === undefined)
      errors.type = languagesCms.ENFORCED;

    if (
      data.legislations === null ||
      data.legislations === undefined ||
      data.legislations === []
    )
      errors.legislations = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.legislations) {
          data.legislations = [data.legislations];
        } else {
          data.legislations = [];
        }
        api
          .put('/rest/emailSender/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/emailSender');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        if (data.legislations) {
          data.legislations = [data.legislations];
        } else {
          data.legislations = [];
        }
        api
          .post('/rest/emailSender', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/emailSender');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/emailSender'}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card
            title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
            loading={loading}
          >
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.GROUP}
                    help={errors.groups}
                    validateStatus={errors.active ? 'error' : 'success'}
                  >
                    <GroupPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="groups"
                    />
                    <small>
                      Boş bıraktığınız taktirde kimseye mail gitmeyecektir!
                    </small>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.TYPE}
                    required
                    help={errors.type}
                    validateStatus={errors.active ? 'error' : 'success'}
                  >
                    <Select
                      defaultValue={
                        data.type ? data.type : languagesCms.SELECT_TYPE
                      }
                      style={{ width: 250 }}
                      onChange={(v) => {
                        setData({ ...data, type: v });
                      }}
                    >
                      <Select.Option value="GENELGE">GENELGE</Select.Option>
                      <Select.Option value="SİRKÜLER MEKTUP">
                        SİRKÜLER MEKTUP
                      </Select.Option>
                      <Select.Option value="İÇ GENELGE">
                        İÇ GENELGE
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Acentalar"
                    required
                    help={errors.sendGroupEmail}
                    validateStatus={errors.sendGroupEmail ? 'error' : 'success'}
                  >
                    <Alert
                      message="'Acentalar' aktif edilirse tekrar düzenleme işlemi yapamazsınız!"
                      banner
                    />
                    <Switch
                      disabled={disabled}
                      checked={data.sendGroupEmail ? true : false}
                      checkedChildren={languagesCms.SEND}
                      unCheckedChildren={languagesCms.DONTSEND}
                      onChange={(v) => setData({ ...data, sendGroupEmail: v })}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="Mevzuatlar"
                    required
                    help={errors.legislations}
                    validateStatus={errors.legislations ? 'error' : 'success'}
                  >
                    <small>Link eklemek için belge seçiniz!</small>
                    <LegislationPicker
                      record={data}
                      setRecord={setData}
                      name="legislations"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Email "
                    required
                    help={errors.sendEmail}
                    validateStatus={errors.sendEmail ? 'error' : 'success'}
                  >
                    <Alert
                      message=" 'Email Gönder' aktif edilirse tekrar düzenleme işlemi yapamazsınız!"
                      banner
                    />
                    <Switch
                      disabled={disabled}
                      checked={data.sendEmail ? true : false}
                      checkedChildren={languagesCms.SEND}
                      unCheckedChildren={languagesCms.DONTSEND}
                      onChange={(v) => setData({ ...data, sendEmail: v })}
                    />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.NOTIFICATION}
                    required
                    help={errors.sendNotification}
                    validateStatus={
                      errors.sendNotification ? 'error' : 'success'
                    }
                  >
                    <SendNotificationInput
                      record={data}
                      setRecord={setData}
                      name="sendNotification"
                      languagesCms={languagesCms}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      disabled={disabled}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default EmailSenderDetail;
