import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { ImportExcel } from './../components';
import moment from 'moment';

import { env } from '../app.config';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';

const AttendeeGroupDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    refId: nanoid(20),
    name: '',
    active: true,
    type: '',
    isRead: true,
    isWrite: true,
  };
  const { isSmall } = useSelector((state) => state.isSmall);
  const { user } = useSelector((state) => state.user);

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [excelData, setExcelData] = useState([]);

  let { modules } = useSelector((state) => state.modules);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/attendeeGroups/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.active == null) errors.active = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/attendeeGroups/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/attendeeGroups');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        await api
          .post('/rest/attendeeGroups', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/attendeeGroups');
            } else {
              message.error(result_message.message, 2);
            }
          });

        if (env.ISPOSTGROUP === 1) {
          let nameW = data.name + '(W)';
          let nameR = data.name + '(R)';

          data.name = nameR;
          data.isWrite = false;
          data.isRead = true;
          await api
            .post('/rest/attendeeGroups', data)
            .then(({ data: { result, result_message } }) => {});

          data.name = nameW;
          data.isWrite = true;
          data.isRead = false;
          await api
            .post('/rest/attendeeGroups', data)
            .then(({ data: { result, result_message } }) => {});
        }
      }
    }
  };
  let parseDateExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  };
  let uploadExcel = async () => {
    //let eventId;
    let eventData;
    let eventGroups = [];
    let events = [];

    let updateCount = 0;
    let insertCount = 0;
    if (excelData) {
      for (let i in excelData) {
        //eventId = excelData[i].id;
        eventGroups = [];
        if (id) {
          eventGroups.push(id.toString().trim());
        }
        let date = new Date(
          (parseInt(excelData[i].startTime) - (25567 + 2)) * 86400 * 1000,
        );
        let unix = date.getTime();
        console.log('clock ', unix);
        console.log('Tarih', excelData[i].startTime);
        console.log(
          'Tarih',
          moment(parseDateExcel(excelData[i].startDate)).format('YYYY-MM-DD'),
        );
        console.log(
          moment(parseDateExcel(excelData[i].startTime)).format('HH:mm'),
        );

        //console.log(moment((excelData[i].startDate)+" "+excelData[i].startTime).format('DD-MM-YYYY HH:mm'))
        eventData = {
          name: excelData[i].name,
          lang: excelData[i].lang || 'TR',
          description: excelData[i].description || '',
          speakers_names: excelData[i].speakers_names,
          limit: excelData[i].limit || null,
          active: excelData[i].active || true,
          showTime: excelData[i].showTime || true,
          content: excelData[i].content,
          type: 'Content',
          releaseDate:
            moment(parseDateExcel(excelData[i].startDate)).format(
              'YYYY-MM-DD',
            ) +
            ' ' +
            moment(parseDateExcel(excelData[i].startTime)).format('HH:mm')
              ? moment(parseDateExcel(excelData[i].startDate)).format(
                  'YYYY-MM-DD',
                ) +
                ' ' +
                moment(parseDateExcel(excelData[i].startTime)).format('HH:mm')
              : '',
          joinEndDate:
            moment(parseDateExcel(excelData[i].startDate)).format(
              'YYYY-MM-DD',
            ) +
            ' ' +
            moment(parseDateExcel(excelData[i].endTime)).format('HH:mm')
              ? moment(parseDateExcel(excelData[i].startDate)).format(
                  'YYYY-MM-DD',
                ) +
                ' ' +
                moment(parseDateExcel(excelData[i].endTime)).format('HH:mm')
              : '',
          startDate:
            moment(parseDateExcel(excelData[i].startDate)).format(
              'YYYY-MM-DD',
            ) +
            ' ' +
            moment(parseDateExcel(excelData[i].startTime)).format('HH:mm')
              ? moment(parseDateExcel(excelData[i].startDate)).format(
                  'YYYY-MM-DD',
                ) +
                ' ' +
                moment(parseDateExcel(excelData[i].startTime)).format('HH:mm')
              : '',
          endDate:
            moment(parseDateExcel(excelData[i].startDate)).format(
              'YYYY-MM-DD',
            ) +
            ' ' +
            moment(parseDateExcel(excelData[i].endTime)).format('HH:mm')
              ? moment(parseDateExcel(excelData[i].startDate)).format(
                  'YYYY-MM-DD',
                ) +
                ' ' +
                moment(parseDateExcel(excelData[i].endTime)).format('HH:mm')
              : '',
          sendNotification: false,
          groups: eventGroups,
        };
        events.push(eventData);
        if (eventData) {
          // update
          await api
            .post('/rest/events', { ...eventData })
            .then(({ data: { result, result_message } }) => {
              message.error('Excel Yüklendi. ', 2);
            });
        }
      }
      if (updateCount || insertCount) {
        message.error(
          'Excel Yüklendi. ' +
            updateCount +
            ' Güncellendi, ' +
            insertCount +
            ' Kayıt Eklendi.',
          2,
        );
      }
    }
  };
  useEffect(() => {
    uploadExcel();
  }, [excelData]);
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/attendeeGroups">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {isSmall ? '' : languagesCms.BACK}
            </Button>
          </Link>
          {!isSmall && id && user && user.role === 'superAdmin' && (
            <ImportExcel setExcelData={setExcelData} />
          )}
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default AttendeeGroupDetail;
