import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Space, Button, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const VideoAlbumItemList = (props) => {
  let params = useParams();
  let query = [];
  let videoAlbum = params.id !== 'add' ? params.id : false;
  query['categoryId'] = videoAlbum;
  let { langCms } = useSelector((state) => state.langCms);
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [loading, setLoading] = useState(false);
  let [category, setCategory] = useState(false);
  let { modules } = useSelector((state) => state.modules);
  let { langCode } = useSelector((state) => state.langCode);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/videoAlbumItems?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        setLoading(false);
        if (data && data.result) {
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  let getCategories = async () => {
    if (videoAlbum) {
      await api
        .get(`/rest/videoAlbumCategories/${videoAlbum}`)
        .then((response) => {
          if (response.data.result.langs) {
            response.data.result.langs.forEach((lang, index) => {
              if (langCode || langCms) {
                if (lang.lang === (langCode ? langCode : langCms[0].code)) {
                  setCategory(lang.title);
                }
              }
            });
          }
        });
    }
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      setCategory(true);
      getCategories();
      get();
    }
  }, []);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/videoAlbumItems/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/videoAlbumItems/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let columns = [
    {
      title: languagesCms.PHOTO,
      dataIndex: 'thumb',
      key: 'thumb',
      render: (text, record) => {
        return (
          <div style={{ width: '100px' }}>
            <img alt="" src={record.thumb} style={{ width: '100%' }} />
          </div>
        );
      },
    },
    {
      title: languagesCms.TITLE,
      dataIndex: 'langs',
      key: 'langs',
      render: (langs) =>
        langs.map((l, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {l.title} - {l.lang}
          </div>
        )),
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {languagesCms.DELETE}
            </Button>
          </Popconfirm>
          <Link to={'/videoAlbum/detail/' + videoAlbum + '/edit/' + record._id}>
            <Button icon={<EditOutlined />}>{languagesCms.EDIT}</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
          <h6 style={{ color: '#fff' }}>{category}</h6>
        </div>
        <div className="list-buttons">
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="large"
            onClick={deleteSelectedRows}
            style={{ marginRight: '5px' }}
          >
            {languagesCms.DELETE_SELECTED}
          </Button>
          <Link to={'/videoAlbum/detail/' + videoAlbum + '/add'}>
            <Button
              type="light"
              icon={<PlusOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {languagesCms.ADD_NEW}
            </Button>
          </Link>
          <Link to="/videoAlbum">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default VideoAlbumItemList;
