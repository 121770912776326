import React, { useState, useEffect } from 'react';
import { Upload, Button, Progress } from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import api from '../service/index';

export default (props) => {
  let { record, setRecord, name } = props;
  let [, setFile] = useState([]);
  let [imageUrl, setImageUrl] = useState(false);
  let [loading, setLoading] = useState(false);
  let [uploadStart, setUploadStart] = useState(false);
  const [progress, setProgress] = useState(0);
  let [_download, set_Download] = useState(false);

  useEffect(() => {
    set_Download(record.imageurl ? record.imageurl : record.url);
    if (record[name]) {
      setImageUrl(record[name]);
    }
  }, []);

  let handleChange = (info) => {
    if (!info.event && uploadStart === false) {
      setUploadStart(true);
      const formData = new FormData();
      formData.append('files_0', info.file.originFileObj);
      api
        .post('/rest/upload', formData, {
          headers: { ContenType: 'multipart/form-data' },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent - 1);
          },
        })
        .then(({ data: { result, result_message } }) => {
          set_Download(result[0].url ? result[0].url : null);
          setTimeout(() => setProgress(100), 1000);
          setTimeout(() => setProgress(0), 2000);
          if (result_message.type === 'success') {
            if (result[0].duration) {
              setTimeout(() => {
                setFile(result[0]);
                setImageUrl(result[0].url);
                setRecord({ ...record, [name]: result[0].url });
                setUploadStart(false);
              }, 1000);
            } else {
              setImageUrl(result[0] ? result[0].thumb : result[0].url);
              setFile(result[0]);
              setRecord({ ...record, [name]: result[0] });
              setUploadStart(false);
            }
          }
          setLoading(false);
        });
    }
  };

  let beforeUpload = () => {
    setImageUrl(false);
    setLoading(true);
  };
  let remove = () => {
    setFile([]);
    setImageUrl(false);
    setRecord({ ...record, [name]: null });
  };

  let downloadImage = (imageUrl) => {
    imageUrl = _download
      ? _download
      : record.imageurl
      ? record.imageurl
      : record.imageUrl
      ? record.imageUrl
      : record.thumb;
    if (!imageUrl.length) return;
    fetch(imageUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        let extension = imageUrl.split('.').reverse()[0];
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `image.${extension}`);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.log('Fotoğraf indirilirken bir hata oluştu');
      });
  };

  const uploadButton = (
    <div
      icon={
        loading ? (
          <LoadingOutlined onClick={() => handleChange()} />
        ) : (
          <PlusOutlined />
        )
      }
    >
      <PlusOutlined /> Upload
    </div>
  );

  return (
    <>
      <Upload
        name={name}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <div>
            <img
              src={imageUrl}
              alt={name}
              style={{ width: '100%', height: 'inherit', objectFit: 'contain' }}
            />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
      {imageUrl ? (
        <div>
          <div>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => remove()}
              style={{ marginRight: '3px' }}
            ></Button>
            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
              <Button
                icon={<EyeOutlined />}
                size="medium"
                style={{ marginRight: '3px' }}
                type="primary"
              ></Button>
            </a>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadImage()}
              size="medium"
            />
          </div>
        </div>
      ) : null}
      {progress > 0 ? (
        <Progress style={{ width: '60%' }} percent={progress} />
      ) : null}
    </>
  );
};
