import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { setCollapsed } from '../stores/collapsed';

const { Sider } = Layout;

const SideMenu = ({ testFunc }) => {
  const dispatch = useDispatch();

  const { isSmall } = useSelector((state) => state.isSmall);
  const { collapsed } = useSelector((state) => state.collapsed);

  let modules = useSelector(
    (state) => state && state.modules && state.modules.modules,
  );

  let logo = useSelector((state) => state && state.logo && state.logo.logo);
  let cpName = useSelector((state) => state && state.loginSetting && state.loginSetting.loginSetting);

  let url = {
    backgroundImage: `url(${logo})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  };
  function tabTitle(titleName) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.title = cpName + ' - ' + titleName;
  }

  const getMenu = () => {
    if (modules && modules.length > 0) {
      return modules.map((item) => {
        let href = item.type;

        if (item.type === 'infos') {
          href = item && item.refId;
        }
        return (
          <Menu.Item key={item.refId} onClick={() => tabTitle(item.name)}>
            <Link to={'/' + href}>
              <img alt="" src={item.icon} width="40" />
              <span>{item.name}</span>
            </Link>
          </Menu.Item>
        );
      });
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => dispatch(setCollapsed(!collapsed))}
      theme="light"
      width={isSmall ? '150' : '260'}
    >
      <div className="logo-wrap">
        <div className="logo" style={url}></div>
      </div>
      <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
        {getMenu()}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
