import React, { useEffect, useState } from "react";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    Loading3QuartersOutlined,
    CloseOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const BloodNeeds = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    const { isSmall } = useSelector((state) => state.isSmall);

    let { modules } = useSelector((state) => state.modules);
    let {languagesCms} = useSelector((state) => state.languagesCms);
    let [loading, setLoading] = useState(false)
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "contactPerson,city,hospitalName";
        }
        let restData = await api.get(`/rest/bloodNeeds?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.bloodDonation)
                        item.bloodDonation = <CheckOutlined/>;
                    else
                        item.bloodDonation = <CloseOutlined/>;
                    if (item.plateletDonation)
                        item.plateletDonation = <CheckOutlined/>;
                    else
                        item.plateletDonation = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/bloodNeeds/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/bloodNeeds/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
      title: languagesCms.NAME,
            dataIndex: 'contactPerson',
            key: 'contactPerson',
            sorter: (a, b) => a.contactPerson - b.contactPerson,
            sortDirections: ['descend', 'ascend']
        },
        {
      title: languagesCms.PHONE,
            dataIndex: 'contactPhone',
            key: 'contactPhone',
            sorter: (a, b) => a.contactPhone - b.contactPhone,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Hastane',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            sorter: (a, b) => a.hospitalName - b.hospitalName,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.CITY,
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => a.city - b.city,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Kan Tipi',
            dataIndex: 'bloodName',
            key: 'bloodName',
            sorter: (a, b) => a.bloodName - b.bloodName,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Kan',
            dataIndex: 'bloodDonation',
            key: 'bloodDonation',
            sorter: (a, b) => a.bloodDonation - b.bloodDonation,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Trombosit',
            dataIndex: 'plateletDonation',
            key: 'plateletDonation',
            sorter: (a, b) => a.plateletDonation - b.plateletDonation,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/bloodNeeds/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to="/bloodNeeds/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder="İsim-Hastane-Şehir Ara" onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>
        </div>
    );
};


export default BloodNeeds;
