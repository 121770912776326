import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './Admin';
import axios from 'axios';
import api from './service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSmall } from './stores/isSmall';
import { setCollapsed } from './stores/collapsed';
import { setUser } from './stores/user';
import { setToken } from './stores/token';
import { setModules } from './stores/modules';
import { setSettings } from './stores/settings';
import { setCustomization } from './stores/customization';
import { setLangCode } from './stores/langCode';
import { setLanguagesCms } from './stores/languagesCms';
import { setLangCms } from './stores/langCms';
import { setLangs } from './stores/langs';
import { setLogo } from './stores/logo';
import { setLoginSetting } from './stores/loginSetting';

axios.interceptors.response.use(
  (result) => {
    return result;
  },
  (err) => {
    const dispatch = useDispatch();
    if (err.response && err.response.status === 401) {
      dispatch(setToken(null));
    }
    return Promise.reject(err);
  },
);

const App = (props) => {
  const dispatch = useDispatch();

  dispatch(setToken(Cookies.get('token')));

  dispatch(
    setUser(
      localStorage.getItem('user') === undefined ||
        localStorage.getItem('user') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('user')),
    ),
  );

  dispatch(
    setModules(
      localStorage.getItem('modules') === undefined ||
        localStorage.getItem('modules') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('modules')),
    ),
  );
  dispatch(
    setSettings(
      localStorage.getItem('settings') === undefined ||
        localStorage.getItem('settings') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('settings')),
    ),
  );
  dispatch(
    setCustomization(
      localStorage.getItem('customization') === undefined ||
        localStorage.getItem('customization') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('customization')),
    ),
  );
  dispatch(
    setLangCode(
      localStorage.getItem('langCode') === undefined ||
        localStorage.getItem('langCode') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('langCode')),
    ),
  );
  dispatch(
    setLanguagesCms(
      localStorage.getItem('languagesCms') === undefined ||
        localStorage.getItem('languagesCms') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('languagesCms')),
    ),
  );
  dispatch(
    setLangCms(
      localStorage.getItem('langsCms') === undefined ||
        localStorage.getItem('langsCms') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('langsCms')),
    ),
  );
  dispatch(
    setLangs(
      localStorage.getItem('langs') === undefined ||
        localStorage.getItem('langs') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('langs')),
    ),
  );
  dispatch(
    setLoginSetting(
      localStorage.getItem('login_setting') === undefined ||
        localStorage.getItem('login_setting') === 'undefined'
        ? {}
        : JSON.parse(localStorage.getItem('login_setting')),
    ),
  );
  dispatch(
    setLogo(
      localStorage.getItem('logo') === undefined ||
        localStorage.getItem('logo') === 'undefined'
        ? ''
        : localStorage.getItem('logo'),
    ),
  );

  const { isSmall } = useSelector((state) => state.isSmall);
  const { token } = useSelector((state) => state.token);
  const { langCode } = useSelector((state) => state.langCode);

  useEffect(() => {
    if (
      window &&
      window.location &&
      window.location.pathname === '/login' &&
      token
    ) {
      return (window.location.pathname = '/');
    }
  }, []);

  let getLangs = async () => {
    await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (data && data.result && data.result.rows) {
        data.result.rows.forEach((element) => {
          if (element._id === 'supportedCmsLangs') {
            dispatch(setLangCms(element.items));
            localStorage.setItem('langsCms', JSON.stringify(element.items));
            if (!langCode) {
              dispatch(setLangCode(element.items[0].code));
            }
          }
        });
      }
    });
  };
  useEffect(() => {
    getLangs();
    window.onresize = () => {
      dispatch(setIsSmall(window.innerWidth < 1024));
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    getLangs();
    if (isSmall || window.innerWidth <= 1024) dispatch(setCollapsed(true));
    else dispatch(setCollapsed(false));
  }, [isSmall, setCollapsed]);

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={Admin} />
      </Switch>
    </Router>
  );
};

export default App;
