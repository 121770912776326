import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import {Table, Button} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
    CheckOutlined,
    CloseOutlined,
    LeftOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const Like = (props) => {
    let params = useParams()
    let query = []
    let like = params.id !== "add" ? params.id : false;
    query["storiesId"] = like;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [, setSelectedRows] = useState([])
    let { modules } = useSelector((state) => state.modules);
    let {languagesCms} = useSelector((state) => state.languagesCms);
    let [loading, setLoading] = useState(false)
    const { isSmall } = useSelector((state) => state.isSmall);

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }
    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }
    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
        }

        let _params = {sort: shortString, ...page, query};
        let restData = await api.get(`/rest/stories/${like}/likes?${qs.stringify(_params)}`, {_params}).then( async ({data}) => {
            if(data && data.result){  
                
            setTotalCount(data.result.total);
            setLoading(false)
            return data.result.map((item, key) => {
                if (item.active)
                    item.active = <CheckOutlined/>;
                else
                    item.active = <CloseOutlined/>;
                if (!item.hasOwnProperty('groups')) {
                    item.groups = []
                }
                item.key = key;
                return item;
            })
        }
        
        });
        setData(restData);
    }
    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])
    
    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let columns = [
        {
            title: languagesCms.USER_WHO_LIKED,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: languagesCms.LIKE_TYPE,
            dataIndex: 'likes',
            key: 'likes',
            render: (text, record) => {
                return (
                <div>
                    {record.likeType === "like" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/like5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "applause" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/applause5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "blueHeart" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/blueheart5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "hope" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/hope5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "laugh" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/laugh5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "love" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/love5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "ok" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/ok5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "party" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/party5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "sad" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/sad5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "smile" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/smile5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                    {record.likeType === "grinning" && (
                    <>
                        <img src="https://cdn.iciletisim.app/iciletisim-dev/like-types/grinning5.png" style={{width: '10%'}} alt=""/>
                    </>) 
                    }
                </div>
                )
            },
            sortDirections: ['descend', 'ascend']
        }
    ];
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/stories">
                        <Button type="light" icon={<LeftOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};

export default Like;
