import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [attendee, setAttendee] = useState([]);
  let _params = {
    query: { deleted: { $ne: true } },
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
  };
  let languagesCms = props.languagesCms;
  useEffect(() => {
    let roomFriends = [];
    api.get('/rest/roommate').then(({ data: { result } }) => {
      result.rows.forEach((item, key) => {
        roomFriends.push(item.reciver._id, item.sender._id);
      });
    });

    api.get(`/rest/attendees?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result) {
        let notDeleted = data.result.rows.filter((z) => z.deleted !== true);
        let dbAttendee = notDeleted.sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        let attendee = dbAttendee.filter(
          (z) => !roomFriends.includes(z._id) && z.roomType === '3',
        );

        // attende status superAdmin olanları listeden çıkartıyoruz
        // eğer istenirse role admin olanları da çıkartılabilir

        // daha önceden eklenmiş olanları listede name + (eklenmiş) şeklinde gösteriyoruz
        let attendeeList = attendee.map((item) => {
          if (roomFriends.includes(item._id)) {
            return {
              ...item,
              name: item.name,
              lastname: item.lastname + ' (DOLU)',
            };
          } else {
            return item;
          }
        });

        let myattendees = attendeeList.filter(
          (z) => z.role !== 'superAdmin' && z.role !== 'admin',
        );

        setAttendee(myattendees);
      }
    });
  }, []);

  let mentionedUsers = (v) => {
    let mentionedUser = attendee.find((e) => e._id === v);

    let attendeeData = {
      _id: mentionedUser._id,
      name: mentionedUser.name,
      lastname: mentionedUser.lastname,
      avatar: mentionedUser.avatar,
      playerId: mentionedUser.playerId,
    };
    setRecord({
      ...record,
      [name]: attendeeData,
      [name + '_id']: attendeeData._id,
    });
    // setAttendee(filteredAttendee);
  };

  return (
    <Select
      defaultValue={
        record[name] ? record[name].name + ' ' + record[name].lastname : ''
      }
      placeholder={languagesCms.SELECT_ATTENDEE}
      showSearch={true}
      onChange={mentionedUsers}
      filterOption={(input, option) =>
        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {attendee &&
        attendee.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name} {b.lastname}
          </Select.Option>
        ))}
    </Select>
  );
};
