import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Switch,
  message,
  DatePicker,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const StorieCommentDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let commentsId = params.commentsId !== 'add' ? params.commentsId : false;

  let { modules } = useSelector((state) => state.modules);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let [data, setData] = useState([]);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false);

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let get = async () => {
    if (id) {
      api
        .get(`/rest/storyComments/comment/${id}`)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  let validate = useCallback(() => {
    let errors = {};

    if (data.comment === '') errors.comment = languagesCms.ENFORCED;

    if (data.date === null) errors.date = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) {
      setErrors(validate());
    }
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        data.attendee_id = data.attendee_id._id;
        api
          .put(`/rest/storyComments/${id}`, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/stories/comments/' + commentsId);
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      }
    }
  };
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/stories/comments/' + commentsId}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.COMMENT_OWNER}>
                  {data && data.attendee_id ? (
                    <Typography.Title
                      style={{ padding: 0, margin: 0 }}
                      level={4}
                    >
                      {data.attendee_name}
                    </Typography.Title>
                  ) : (
                    <Typography.Title
                      style={{ padding: 0, margin: 0 }}
                      level={4}
                    >
                      {'Daha önce silinmiş bir kullanıcı.'}
                    </Typography.Title>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.COMMENT}
                  required
                  help={errors.comment}
                  validateStatus={errors.comment ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="comment"
                    value={data.comment}
                    onChange={(e) =>
                      setData({ ...data, comment: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.DATE}
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.date)}
                    onChange={(v) => setData({ ...data, date: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default StorieCommentDetail;
