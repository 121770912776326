import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row,Col,Form, Input, Button, Switch, DatePicker, Card, message ,Select} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { CityPicker} from '../components';


import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import { useSelector } from "react-redux";

const BloodNeedsDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;

  let newRecord = {
    contactPerson: '',
    contactPhone: '',
    hospitalName: '',
    startDate: new Date(),
    endDate: new Date(),
    bloodDonation:false,
    plateletDonation:false
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

   
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
      api.get("/rest/bloodNeeds/" + id).then(({ data: { result, result_message } }) => {
          setData(result);   
          setLoading(false)
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
     if (data.contactPhone == null || data.contactPhone.length === 0)
      errors.contactPhone = languagesCms.ENFORCED

    if (data.hospitalName == null || data.hospitalName.length === 0)
      errors.hospitalName = languagesCms.ENFORCED

    if (data.contactPerson == null || data.contactPerson.length === 0)
      errors.contactPerson = languagesCms.ENFORCED

    if (data.city == null)
      errors.city = languagesCms.ENFORCED

    if (data.bloodName == null)
      errors.bloodName = languagesCms.ENFORCED

    if (data.startDate == null)
      errors.startDate = languagesCms.ENFORCED

    if (data.endDate == null)
      errors.endDate = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if(id) {
        api.put("/rest/bloodNeeds/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/bloodNeeds')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/bloodNeeds", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/bloodNeeds')
          } else {
            message.error(result_message.message, 2);
          }
        })
      } 
    }        
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/bloodNeeds">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.NAME} required help={errors.contactPerson} validateStatus={errors.contactPerson ? 'error' : 'success'}>
                  <Input name="contactPerson" value={data.contactPerson} onChange={e => setData({ ...data, contactPerson: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.PHONE} required help={errors.contactPhone} validateStatus={errors.contactPhone ? 'error' : 'success'}>
                  <Input name="contactPhone" value={data.contactPhone} onChange={e => setData({ ...data, contactPhone: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Hastane" required help={errors.hospitalName} validateStatus={errors.hospitalName ? 'error' : 'success'}>
                  <Input name="hospitalName" value={data.hospitalName} onChange={e => setData({ ...data, hospitalName: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Kan Tipi" required  help={errors.bloodName} validateStatus={errors.bloodName ? 'error' : 'success'}>
                <Select defaultValue={(data.bloodName)?data.bloodName:'Kan Tipi Seçiniz'} style={{ width: 250 }} onChange={v => {setData({ ...data, bloodName: v })}}>
                <Select.Option value="A Rh+">A Rh+</Select.Option>
                <Select.Option value="A Rh-">A Rh-</Select.Option>
                <Select.Option value="B Rh+">B Rh+</Select.Option>
                <Select.Option value="B Rh-">B Rh-</Select.Option>
                <Select.Option value="0 Rh+">0 Rh+</Select.Option>
                <Select.Option value="0 Rh-">0 Rh-</Select.Option>
                <Select.Option value="AB Rh+">AB Rh+</Select.Option>
                <Select.Option value="AB Rh-">AB Rh-</Select.Option>
              </Select>
              
              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.CITY} required help={errors.city} validateStatus={errors.city ? 'error' : 'success'}>
                    <CityPicker languagesCms={languagesCms} record = {data} setRecord = {setData} name = "city" mode = "single" />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
               <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Kan" help={errors.bloodDonation} validateStatus={errors.bloodDonation ? 'error' : 'success'}>
                  <Switch checked={data.bloodDonation ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, bloodDonation: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Trombosit" help={errors.plateletDonation} validateStatus={errors.plateletDonation ? 'error' : 'success'}>
                <Switch checked={data.plateletDonation ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, plateletDonation: v })} />
              </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label={languagesCms.START_DATE}required help={errors.startDate} validateStatus={errors.startDate ? 'error' : 'success'}>
                <DatePicker defaultValue={() => moment(data.startDate)} onChange={v => setData({ ...data, startDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
              </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label={languagesCms.END_DATE}required help={errors.endDate} validateStatus={errors.endDate ? 'error' : 'success'}>
                <DatePicker defaultValue={() => moment(data.endDate)} onChange={v => setData({ ...data, endDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
              </Form.Item>
              </Col>
            </Row>
            
            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};



export default BloodNeedsDetail;
