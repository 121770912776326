import React from 'react';
import { useSelector } from 'react-redux';

const Login = (props) => {
  let { modules } = useSelector((state) => state.modules);
  if (modules) {
    let module = modules[0].refId;
    props.history.push(module);
  } else props.history.push('login');

  return <div className="login-wrap"> </div>;
};
export default Login;
