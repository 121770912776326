import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customization: null,
};

const customization = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    setCustomization: (state, action) => {
      state.customization = action.payload;
    },
  },
});

export const { setCustomization } = customization.actions;
export default customization.reducer;
