import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logo: null,
};

const logo = createSlice({
  name: 'logo',
  initialState,
  reducers: {
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
  },
});

export const { setLogo } = logo.actions;
export default logo.reducer;
