import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  langs: {},
};

const langs = createSlice({
  name: 'langs',
  initialState,
  reducers: {
    setLangs: (state, action) => {
      state.langs = action.payload;
    },
  },
});

export const { setLangs } = langs.actions;
export default langs.reducer;
