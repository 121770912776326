import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    collapsed: false,
};

const collapsed = createSlice({
  name: 'collapsed',
  initialState,
  reducers: {
    setCollapsed: (state, action) => {
      state.collapsed  = action.payload;
    },
  },
});

export const { setCollapsed } = collapsed.actions;
export default collapsed.reducer;
