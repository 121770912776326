import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Card, message, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { DbSelectAttendee, DbSelectPlan, GroupPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const FlightDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    attendee: '',
    departure: '',
    arrival: '',
    arrivalService: '',
    departureService: '',
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/flight/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        console.log('data', data);
        api
          // .put('/rest/flight/' + id, data)
          .post('/rest/flight/submit', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/flight');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/flight', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/flight');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/flight">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kullanıcı"
                  required
                  help={errors.type}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <DbSelectAttendee
                    url="attendees"
                    placeholder="Kullanıcı"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="attendee"
                    mode="single"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Uçuş Türü"
                  required
                  help={errors.type}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checkedChildren={'Uçak İle'}
                    unCheckedChildren={'Kendi Aracım İle'}
                    initialChecked={
                      data.departureType === 'AIRPORT' ? 'AIRPORT' : 'CAR'
                    }
                    record={data}
                    setRecord={setData}
                    name="airport"
                    checked={data.departureType === 'AIRPORT' ? true : false}
                    onChange={(v) =>
                      setData({
                        ...data,
                        departureType: v ? 'AIRPORT' : 'CAR',
                        arrivalType: v ? 'AIRPORT' : 'CAR',
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            {data.departureType === 'AIRPORT' && (
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Gidiş"
                    required
                    help={errors.departure}
                    validateStatus={errors.departure ? 'error' : 'success'}
                  >
                    <DbSelectPlan
                      url="flightPlan"
                      filterName="type"
                      filterValue="DEPARTURE"
                      placeholder="Plan Seçin"
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="departure"
                      mode="single"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Dönüş"
                    required
                    help={errors.arrival}
                    validateStatus={errors.arrival ? 'error' : 'success'}
                  >
                    <DbSelectPlan
                      url="flightPlan"
                      filterName="type"
                      filterValue="ARRIVAL"
                      placeholder="Plan Seçin"
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="arrival"
                      mode="single"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FlightDetail;
