import React, { useState, useEffect } from 'react';
import { Upload, message, Progress, Button } from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import api from '../service/index';

export default (props) => {
  let { record, setRecord, name, thumb, url } = props;
  let [, setFile] = useState([]);
  let [imageUrl, setImageUrl] = useState(false);
  let [videoUrl, setVideoUrl] = useState(false);
  let [loading, setLoading] = useState(false);
  let [uploadStart, setUploadStart] = useState(false);
  let languagesCms = props.languagesCms;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (record[name]) {
      setImageUrl(record[thumb]);
      setVideoUrl(record[url]);
    }
  }, []);

  let handleChange = (info) => {
    const formData = new FormData();
    formData.append('files_0', info.file.originFileObj);

    if (!info.event && uploadStart === false) {
      setUploadStart(true);
      if (info.file.originFileObj.size > 56844918)
        message.warning(languagesCms.WARNING_FILE_SIZE);
      else {
        api
          .post('/rest/upload', formData, {
            headers: { ContenType: 'multipart/form-data' },
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setProgress(percent - 1);
            },
          })
          .then(({ data: { result, result_message } }) => {
            setTimeout(() => setProgress(100), 1000);
            setTimeout(() => setProgress(0), 2000);
            if (result_message.type === 'success') {
              setFile(result[0]);
              setVideoUrl(result[0].url);
              setImageUrl(result[0].thumb);
              let thumbNew = !record[thumb] ? result[0].thumb : record[thumb];

              setRecord({
                ...record,
                [name]: result[0].url,
                [thumb]: thumbNew,
              });
              setUploadStart(false);
            }
            setLoading(false);
          });
      }
    }
  };
  let downloadVideo = (imageUrl) => {
    imageUrl = videoUrl ? videoUrl : record.imageurl;
    if (!imageUrl.length) return;
    fetch(imageUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        let extension = imageUrl.split('.').reverse()[0];
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `image.${extension}`);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((error) => {
        console.log('Fotoğraf indirilirken bir hata oluştu');
      });
  };

  let beforeUpload = () => {
    setImageUrl(false);
    setLoading(true);
  };
  let remove = () => {
    setFile([]);
    setImageUrl(false);
    setRecord({ ...record, [name]: null });
  };
  const uploadButton = (
    <div
      icon={
        loading ? (
          <LoadingOutlined onClick={() => handleChange()} />
        ) : (
          <PlusOutlined />
        )
      }
    >
      <PlusOutlined /> Upload
    </div>
  );

  return (
    <>
      <Upload
        name={name}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt={name} style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
      {imageUrl ? (
        <div>
          <div>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => remove()}
              style={{ marginRight: '3px' }}
            ></Button>
            <a href={videoUrl}>
              <Button
                icon={<EyeOutlined />}
                size="medium"
                style={{ marginRight: '3px' }}
                type="primary"
              ></Button>
            </a>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadVideo()}
              size="medium"
            />
          </div>
        </div>
      ) : null}
      {progress > 0 ? (
        <Progress style={{ width: '60%' }} percent={progress} />
      ) : null}
    </>
  );
};
