import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  langCode: null,
};

const langCode = createSlice({
  name: 'langCode',
  initialState,
  reducers: {
    setLangCode: (state, action) => {
      state.langCode = action.payload;
    },
  },
});

export const { setLangCode } = langCode.actions;
export default langCode.reducer;
