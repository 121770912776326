import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Modal,
  Select,
  Popconfirm,
  InputNumber,
  Switch,
} from 'antd';
import { LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Parse from 'parse';
import ImageMapper from 'react-image-mapper';
import { useSelector } from 'react-redux';

const PlanCoordinates = (props) => {
  let params = useParams();
  let placeId = params.placeId !== 'add' ? params.placeId : false;
  let id = params.id !== 'add' ? params.id : false;
  let [loading, setLoading] = useState(id ? true : false);

  let [image, setImage] = useState('');
  let [areas, setAreas] = useState(null);
  let [coords, setCoords] = useState(null);
  let [map, setMap] = useState({});
  let [radius, setRadius] = useState(10);
  let [imageWidth, setImageWidth] = useState(null);
  let [imageHeight, setImageHeight] = useState(null);
  let [componentWidth, setComponentWidth] = useState(null);
  let [componentHeight, setComponentHeight] = useState(null);
  let [loaded, setLoaded] = useState(false);

  let mapper = useRef();
  let componentMapper = useRef();

  let { modules } = useSelector((state) => state.modules);
  let { customization } = useSelector((state) => state.customization);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let parseServerURL = customization.parseServerURL;
  let parseAppId = customization.parseAppId;

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;
  let Plan = Parse.Object.extend('Plan');
  let PlanCoordinates = Parse.Object.extend('PlanCoordinates');

  // default olarak image boyutlarının girilmesi
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      let query = new Parse.Query(Plan);
      query.get(id).then(
        (plan) => {
          setImage(plan.attributes.planImageUrl.url);
          setImageWidth(plan.attributes.planImageUrl.width);
          setImageHeight(plan.attributes.planImageUrl.height);
          setComponentWidth(plan.attributes.planImageUrl.width);
          setComponentHeight(plan.attributes.planImageUrl.height);
          setLoading(false);
          setLoaded(false);
        },
        (error) => {
          message.error('Kayıt bilgilerine ulaşılamadı.', 2);
        },
      );
    }
  }, [id]);

  // ekran boyutuna göre areaların belirlenmesi
  useEffect(() => {
    if (imageWidth && imageHeight) {
      let queryCoordinates = new Parse.Query(PlanCoordinates);
      queryCoordinates.containedIn('planId', [id]);
      queryCoordinates.limit(1000000);
      queryCoordinates
        .find()
        .then(function (results) {
          setAreas(
            results.map((item, key) => {
              let itemAtt = item.attributes;
              let x = (itemAtt.x * componentWidth) / imageWidth;
              let y = (itemAtt.y * componentHeight) / imageHeight;
              if (item.get('isAvailable')) {
                return {
                  id: item.id,
                  name: itemAtt.name,
                  shape: 'circle',
                  coords: [x, y, radius],
                  preFillColor: 'green',
                  fillColor: '#0000ff',
                };
              } else {
                return {
                  id: item.id,
                  name: itemAtt.name,
                  shape: 'circle',
                  coords: [x, y, radius],
                  preFillColor: 'red',
                  fillColor: '#0000ff',
                };
              }
            }),
          );
        })
        .catch(function (error) {
          setAreas([]);
        });
    }
    setLoaded(false);
  }, [
    imageWidth,
    imageHeight,
    componentWidth,
    componentHeight,
    radius,
    loaded,
  ]);

  // areas her güncellendiğinde map bilgisinin de güncellenmesi
  useEffect(() => {
    if (areas) {
      setMap({
        name: 'my-map',
        areas: areas,
      });
    }
    setLoaded(false);
  }, [areas]);

  // ekran boyutuna göre ayarlama işlemleri
  if (componentMapper.current) {
    if (
      componentMapper.current.offsetWidth &&
      componentMapper.current.offsetWidth !== componentWidth
    ) {
      setComponentWidth(componentMapper.current.offsetWidth);
      if (componentMapper.current.offsetWidth > 800) {
        setRadius(10);
      }
      if (
        componentMapper.current.offsetWidth < 800 &&
        componentMapper.current.offsetWidth > 600
      ) {
        setRadius(7);
      }
      if (
        componentMapper.current.offsetWidth < 600 &&
        componentMapper.current.offsetWidth > 400
      ) {
        setRadius(5);
      }
      if (
        componentMapper.current.offsetWidth < 400 &&
        componentMapper.current.offsetWidth > 0
      ) {
        setRadius(3);
      }
    }
    if (
      componentMapper.current.offsetHeight &&
      componentMapper.current.offsetHeight !== componentHeight
    ) {
      setComponentHeight(componentMapper.current.offsetHeight);
    }
  }

  //yeni koordinat ekleme işlemi yapılacağında gerekli bilgilerin doldurulması ve pop up açma
  let clickedOutside = (evt) => {
    let x = (evt.nativeEvent.layerX * imageWidth) / componentWidth;
    let y = (evt.nativeEvent.layerY * imageHeight) / componentHeight;
    let coords = {
      x: x,
      y: y,
      sequence: 0,
      table: 0,
      chair: 0,
      name: '',
      planId: id,
      isAvailable: true,
      type: 'public',
    };
    setCoords(coords);
  };

  // koordinat düzenleme işlemi yapılacağında gerekli bilgilerin doldurulması ve pop up açma
  let clickedArea = (area) => {
    let query = new Parse.Query(PlanCoordinates);
    query
      .get(area.id)
      .then(function (result) {
        setCoords({ ...result.attributes, id: result.id });
      })
      .catch(function (error) {
        setCoords({});
      });
  };

  //koordinat ekleme ve düzenleme işlemi
  let clickedOk = (coordinate) => {
    //koordinat düzenleme işlemi
    if (coordinate.id) {
      //koordinat güncelleme işlemi
      if (coordinate.name) {
        let query = new Parse.Query(PlanCoordinates);
        query.equalTo('objectId', coordinate.id);
        query.first().then(
          (object) => {
            object.save(coordinate).then(
              () => {
                let data = object.attributes;
                //düzenlene koordinatın areasının düzenlenmesi  ve ekrandaki koordinat verilerinin güncellenmesi
                let newData = areas.filter((area) => area.id !== coordinate.id);
                let [newArea] = areas.filter(
                  (area) => area.id === coordinate.id,
                );
                if (data.isAvailable) newArea.preFillColor = 'green';
                else newArea.preFillColor = 'red';
                setAreas([...newData, newArea]);
                message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                setCoords(null);
              },
              (error) => {
                message.error('Kayıt düzünlenemedi', 2);
                setCoords(null);
              },
            );
          },
          (error) => {
            message.error('Kayıt bilgilerine ulaşılamadı.', 2);
          },
        );
      } else message.error("'İsim' alanını doldurunuz.!", 2);
    }
    //koordinat ekleme işlemi
    else {
      //yeni koordinat ekleme işlemi
      if (coordinate.name) {
        let query = new Parse.Query(Plan);
        query.get(coordinate.planId).then(
          (plan) => {
            let myCoordinate = new PlanCoordinates();
            coordinate.planId = plan;
            myCoordinate.save(coordinate).then(
              (newCoordinate) => {
                let data = newCoordinate.attributes;
                let x = (data.x * componentWidth) / imageWidth;
                let y = (data.y * componentHeight) / imageHeight;

                //ekelenen koordinatın area şekline dönüştürülmesi ve ekrandaki koordinat verilerinin güncellenmesi
                let newArea = {
                  id: newCoordinate.id,
                  name: data.name,
                  shape: 'circle',
                  coords: [x, y, radius],
                  preFillColor: 'red',
                  fillColor: '#0000ff',
                };
                if (data.isAvailable) {
                  newArea = {
                    id: newCoordinate.id,
                    name: data.name,
                    shape: 'circle',
                    coords: [x, y, radius],
                    preFillColor: 'green',
                    fillColor: '#0000ff',
                  };
                }
                setAreas([...areas, newArea]);
                mapper.current.props.map.areas = [...areas, newArea];
                message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                setCoords(null);
              },
              (error) => {
                message.error(error, 2);
              },
            );
          },
          (error) => {
            message.error('Kayıt bilgilerine ulaşılamadı.', 2);
          },
        );
      } else message.error("'İsim' alanını doldurunuz.!", 2);
    }
  };

  //koordinat silme işlemi
  let deleteCoordinate = async (coordinate) => {
    let query = new Parse.Query(PlanCoordinates);
    query.equalTo('objectId', coordinate);
    let object = await query.first();
    object.destroy().then(
      () => {
        //silinen koordinatın ekrandan kaldırılması işlemi
        let newData = areas.filter((area) => area.id !== coordinate);
        setAreas(newData);
        mapper.current.props.map.areas = newData;

        // // Bu alana ait rezervasyonlar bulunup siliniyor.
        // let queryReservation = new Parse.Query(Reservation);
        // queryReservation.equalTo("itemId", coordinate);
        // queryReservation.find().then((reservationObjects) => {
        //     reservationObjects.forEach(element => {
        //         element.destroy().then(() => {
        //             message.success("Rezervasyon silindi.", 2);
        //         }, (error) => {
        //             message.error("Rezervasyon silinemedi", 2);
        //         });
        //     });
        // }, (error) => {
        //     message.error("", 2);
        // });

        message.success('Kayıt silindi', 2);
      },
      (error) => {
        message.error(languagesCms.ERROR_DELETE, 2);
      },
    );
    setCoords(null);
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''} - Oturma Alanı İşlemleri</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/place/' + placeId + '/plan'}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>{' '}
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'}>
            <Row direction="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 14 }}
                xl={{ span: 18 }}
              >
                <ul>
                  <li>
                    Resim üzerine tıklayarak oturma alanı oluşturabilir,
                    oluşturulan alanlara tıklayarak bilgileri
                    düzenleyebilirsiniz.
                  </li>
                  <li>
                    <b>Yeşil</b> alanlar rezervasyon işlemine açık alanlardır.
                  </li>
                  <li>
                    <b>Kırmızı</b> alanlar rezervasyon işlemine kapalı
                    alanlardır.
                  </li>
                </ul>
              </Col>
              {coords && (
                <div>
                  {/*yeni koordinat ekelndiğinde ve koordinatlar üzerine tıklanınca açılacak pop up. Koordinat bilgileri içerir*/}
                  <Modal
                    title={''}
                    visible={true}
                    onOk={() => clickedOk(coords)}
                    onCancel={() => setCoords(null)}
                  >
                    <Card
                      title={
                        coords.id ? languagesCms.EDIT : languagesCms.ADD_NEW
                      }
                      loading={coords ? false : true}
                    >
                      <Form>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label="x">
                              <Input
                                name="x"
                                disabled={true}
                                value={coords.x}
                                onChange={(e) =>
                                  setCoords({ ...coords, x: e.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label="y">
                              <Input
                                name="y"
                                disabled={true}
                                value={coords.y}
                                onChange={(e) =>
                                  setCoords({ ...coords, y: e.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label="isim" required>
                              <Input
                                name="name"
                                value={coords.name}
                                onChange={(e) =>
                                  setCoords({ ...coords, name: e.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label={languagesCms.ORDER}>
                              <InputNumber
                                name="sequence"
                                value={coords.sequence}
                                defaultValue={0}
                                onChange={(v) =>
                                  setCoords({ ...coords, sequence: v })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label="Masa">
                              <InputNumber
                                name="table"
                                value={coords.table}
                                defaultValue={0}
                                onChange={(v) =>
                                  setCoords({ ...coords, table: v })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label="Sandalye">
                              <InputNumber
                                name="chair"
                                value={coords.chair}
                                defaultValue={0}
                                onChange={(v) =>
                                  setCoords({ ...coords, chair: v })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item label={languagesCms.STATUS}>
                              <Switch
                                checked={coords.isAvailable ? true : false}
                                checkedChildren="Kullanılabilir"
                                unCheckedChildren="Kullanılamaz"
                                onChange={(v) =>
                                  setCoords({ ...coords, isAvailable: v })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item label={languagesCms.TYPE}>
                              <Select
                                defaultValue={coords.type}
                                style={{ width: 250 }}
                                onChange={(v) => {
                                  setCoords({ ...coords, type: v });
                                }}
                              >
                                <Select.Option value="public">
                                  Havuz Masa
                                </Select.Option>
                                <Select.Option value="admin">
                                  Admin Only
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        {coords.id && (
                          <Popconfirm
                            onConfirm={() => deleteCoordinate(coords.id)}
                            title={languagesCms.CHECK_DELETE}
                            okText={languagesCms.OK_TEXT}
                            cancelText={languagesCms.CANCEL_TEXT}
                          >
                            <Button type="danger" icon={<DeleteOutlined />}>
                              Sil
                            </Button>
                          </Popconfirm>
                        )}
                      </Form>
                    </Card>
                  </Modal>
                </div>
              )}
              {map.areas && (
                <Col ref={componentMapper}>
                  {areas && image && (
                    <ImageMapper
                      ref={mapper}
                      src={image || ''}
                      map={map}
                      width={componentWidth}
                      onImageClick={clickedOutside}
                      onClick={(area) => clickedArea(area)}
                      onLoad={() => setLoaded(true)}
                    />
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PlanCoordinates;
