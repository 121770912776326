import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../stores/user';
import { setToken } from '../stores/token';
import { setModules } from '../stores/modules';
import { setSettings } from '../stores/settings';
import { setLogoEN } from '../stores/logoEn';
import { setCustomization } from '../stores/customization';
import { setLangCode } from '../stores/langCode';
import { setLanguagesCms } from '../stores/languagesCms';
import { setLangs } from '../stores/langs';
import { setLogo } from '../stores/logo';
import { setPdfIcon } from '../stores/pdfIcon';
import { setLoginSetting } from '../stores/loginSetting';

const { Option } = Select;

const Login = (props) => {
  const dispatch = useDispatch();

  let { langCms } = useSelector((state) => state.langCms);
  const { langCode } = useSelector((state) => state.langCode);

  const auth = async ({ username, password }) => {
    let params = {
      email: username,
      password: password,
      loginType: 'emailPass',
    };

    let queryLangCms = {
      langCode: langCode
        ? langCode
        : langCms && langCms[0] && langCms[0].code
        ? langCms[0].code
        : 'TR',
    };

    delete api.headers['Authorization'];
    await api
      .post('/rest/login', params)
      .then(async ({ data }) => {
        try {
          dispatch(setToken(data.result.token));
          dispatch(setUser(data.result));
          localStorage.setItem('user', JSON.stringify(data.result));

          api.setHeader('Authorization', 'Bearer ' + data.result.token);

          Cookies.set('token', data.result.token);
        } catch (error) {
          console.log('error: ', error);
        }

        await api
          .get(`/rest/languagesCms?${qs.stringify(queryLangCms)}`)
          .then(({ data }) => {
            if (langCode) {
              const language =
                data &&
                data.result &&
                data.result.rows.find((e) => e._id === langCode || 'TR');

              dispatch(setLanguagesCms(language.items));
              localStorage.setItem(
                'languagesCms',
                JSON.stringify(language.items),
              );
              props.history.push(language.type);
            } else {
              const language =
                data &&
                data.result &&
                data.result.rows.find(
                  (e) =>
                    e._id ===
                    ((langCms && langCms[0] && langCms[0].code) || 'TR'),
                );

              dispatch(setLanguagesCms(language.items));
              localStorage.setItem(
                'languagesCms',
                JSON.stringify(language.items),
              );
              props.history.push(language.type);
            }
          });

        await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
          dispatch(setSettings(data.result.rows));
          if (data.result.rows) {
            data.result.rows.forEach((element) => {
              if (element._id === 'customization') {
                dispatch(setCustomization(element));
                localStorage.setItem('customization', JSON.stringify(element));
                dispatch(setLogo(element.cmsLogo));
                localStorage.setItem('logo', JSON.stringify(element.cmsLogo));
                dispatch(setLogoEN(element.cmsLogoEN));
                localStorage.setItem(
                  'cmsLogoEN',
                  JSON.stringify(element.cmsLogoEN),
                );
              }
              if (element._id === 'login_setting') {
                dispatch(setLoginSetting(element.companyName));
                localStorage.setItem(
                  'login_setting',
                  JSON.stringify(element.companyName),
                );
              }
              if (element._id === 'supportedLangs') {
                dispatch(setLangs(element.items));
                localStorage.setItem('langs', JSON.stringify(element.items));
              }
              if (element._id === 'surveyWithMediaIcons') {
                dispatch(setPdfIcon(element.pdf));
                localStorage.setItem('pdfIcon', JSON.stringify(element.pdf));
              }
            });
          }
          localStorage.setItem('settings', JSON.stringify(data.result.rows));
        });
        await api.get(`/rest/getAdminModules`).then(({ data }) => {
          dispatch(setModules(data.result));
          localStorage.setItem('modules', JSON.stringify(data.result));
          props.history.push(data.result[0].type);
        });

        return (window.location.href = '/');
      })
      .catch((e) => {
        console.log(e);
        alert('Password or Username is incorrect');
      });
  };

  const onFinish = async (values) => {
    await auth(values);
  };

  function handleMenuClick(e) {
    localStorage.setItem('langCode', JSON.stringify(e));
    dispatch(setLangCode(e));
  }

  return (
    <div className="login-wrap">
      <div className="form">
        <Form
          name="normal_login"
          className="login-form"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="logo"></div>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {langCms && langCms.length > 1 && (
            <Form.Item>
              <Select
                defaultValue={langCms[0].code}
                style={{ width: 120 }}
                onChange={(e) => handleMenuClick(e)}
              >
                {langCms.map((element, index) => (
                  <Option key={index} value={element.code}>
                    {element.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="light"
              htmlType="submit"
              className="login-form-button"
              id="1"
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
