import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSmall: false,
};

const isSmall = createSlice({
  name: 'isSmall',
  initialState,
  reducers: {
    setIsSmall: (state, action) => {
      state.isSmall  = action.payload;
    },
  },
});

export const { setIsSmall } = isSmall.actions;
export default isSmall.reducer;
