import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { DbSelect } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const FlightAirportDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    code: '',
    name: '',
    city: '',
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    module = modules && modules.find((el) => el._id === path);
  }

  useEffect(() => {
    if (module && id) {
      setLoading(true);
      api
        .get('/rest/flightAirport/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.code === null || data.code.length === 0)
      errors.code = languagesCms.ENFORCED;

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.city === {} || data.city.length === 0)
      errors.city = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/flightAirport/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/flight/airport');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/flightAirport', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/flight/airport');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Havalimanı</h1>
        </div>
        <div className="list-buttons">
          <Link to="/flight">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Havalimanı Adı"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="code"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Havalimanı Kodu"
                  required
                  help={errors.code}
                  validateStatus={errors.code ? 'error' : 'success'}
                >
                  <Input
                    name="code"
                    value={data.code}
                    onChange={(e) => setData({ ...data, code: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Şehir"
                  required
                  help={errors.city}
                  validateStatus={errors.city ? 'error' : 'success'}
                >
                  <DbSelect
                    url="flightCities"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="city"
                    mode="single"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FlightAirportDetail;
