import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    langCms: {},
};

const langCms = createSlice({
  name: 'langCms',
  initialState,
  reducers: {
    setLangCms: (state, action) => {
      state.langCms  = action.payload;
    },
  },
});

export const { setLangCms } = langCms.actions;
export default langCms.reducer;
