import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Switch,
  DatePicker,
  Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FileInputSingle, FileMp3InputSingle } from '../components';
import { GroupPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const PodcastItemsDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let podcasts = params.id !== 'add' ? params.id : false;
  let id = params.subid !== 'add' ? params.subid : false;

  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);

  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let newRecord = {
    title: '',
    category_id: params.id,
    active: true,
    private: true,
    startDate: new Date(),
    endDate: new Date(),
    groups: [],
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false);

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/podcastItems/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let validate = useCallback(() => {
    let errors = {};

    if (data.title == null || data.title.length === 0)
      errors.title = languagesCms.ENFORCED;

    if (data.icon == null) errors.icon = languagesCms.ENFORCED;

    if (data.cover == null) errors.cover = languagesCms.ENFORCED;

    if (data.items == null) errors.items = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/podcastItems/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/podcasts/detail/' + podcasts);
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/podcastItems', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/podcasts/detail/' + podcasts);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/podcasts/detail/' + podcasts}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="title"
                    value={data.title}
                    multiple={true}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.START_DATE}
                  required
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.startDate)}
                    onChange={(v) => setData({ ...data, startDate: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.END_DATE}
                  required
                  help={errors.endDate}
                  validateStatus={errors.endDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.endDate)}
                    onChange={(v) => setData({ ...data, endDate: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="İcon"
                  required
                  help={errors.icon}
                  validateStatus={errors.icon ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="icon"
                  />
                  <Alert message={languagesCms.AVATAR_PIXEL} banner />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.COVER_MEDIA}
                  required
                  help={errors.cover}
                  validateStatus={errors.cover ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="cover"
                  />
                  <Alert message={languagesCms.MEDIA_CONTENTS_MESSAGE} banner />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.RECORD}
                  required
                  help={errors.items}
                  validateStatus={errors.items ? 'error' : 'success'}
                >
                  <FileMp3InputSingle
                    languagesCms={languagesCms}
                    record={data}
                    ext="audio/*"
                    setRecord={setData}
                    name="items"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PRIVATE}
                  help={errors.private}
                  validateStatus={errors.private ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.private ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, private: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PodcastItemsDetail;
