import React, { useState, useEffect } from 'react';
import { Select, Col, Row } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [attendee, setAttendee] = useState([]);
  let _params = {
    query: { deleted: { $ne: true } },
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
  };
  let languagesCms = props.languagesCms;
  useEffect(() => {
    let roomFriends = [];
    api.get('/rest/roommate').then(({ data: { result } }) => {
      result.rows.forEach((item, key) => {
        roomFriends.push(item.reciver._id, item.sender._id);
      });
    });

    api.get(`/rest/attendees?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result) {
        let notDeleted = data.result.rows.filter((z) => z.deleted !== true);
        let dbAttendee = notDeleted.sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        let attendee = dbAttendee.filter(
          (z) => !roomFriends.includes(z._id) && z.roomType === '2',
        );

        // daha önceden eklenmiş olanları listede name + (eklenmiş) şeklinde gösteriyoruz
        let attendeeList = attendee.map((item) => {
          if (roomFriends.includes(item._id)) {
            return {
              ...item,
              name: item.name,
              lastname: item.lastname + ' (DOLU)',
            };
          } else {
            return item;
          }
        });

        // attende status superAdmin olanları listeden çıkartıyoruz
        // eğer istenirse role admin olanları da çıkartılabilir
        attendee = attendeeList.filter(
          (z) => z.role !== 'superAdmin' && z.role !== 'admin',
        );

        setAttendee(attendee);
      }
    });
  }, []);

  let mentionedUsers = (v) => {
    // seçilen attendee
    let mentionedUser = attendee.find((e) => e._id === v);

    // seçilen attendee bilgileri
    let attendeeData = {
      _id: mentionedUser._id,
      name: mentionedUser.name,
      lastname: mentionedUser.lastname,
      avatar: mentionedUser.avatar,
      playerId: mentionedUser.playerId,
    };

    // seçilen attendee bilgileri ile record bilgileri birleştirilir
    setRecord({
      ...record,
      [name]: attendeeData,
      [name + '_id']: attendeeData._id,
    });
  };

  return (
    <>
      <Row direction="vertical">
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Select
            value={
              record[name]
                ? record[name].name + ' ' + record[name].lastname
                : ''
            }
            name={name}
            showSearch
            style={{ width: '100%' }}
            placeholder={languagesCms.attendee}
            onChange={mentionedUsers}
            filterOption={(input, option) =>
              option.props.children[0]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.children[2]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {attendee.map((item, key) => {
              return (
                <Select.Option key={key} value={item._id}>
                  {item.name} {item.lastname}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    </>
  );
};
