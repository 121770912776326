import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: null,
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setSettings } = settings.actions;
export default settings.reducer;
