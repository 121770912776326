import React, { useEffect, useState } from "react";
import { Table, Space, Button, Input, message } from 'antd';
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import { useParams } from 'react-router-dom'

import {  LeftOutlined, PlusOutlined, MinusOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const AttendeeGroupModules = (props) => {

  let params = useParams()
  let id = params.subid;
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [dataModules, setDataModules] = useState([]);
  let [search, setSearch] = useState(false)
  let [loading, setLoading] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter(el => el._id === path);
  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  }

  let get = async (sorter) => {

    let _params = { sort: "order", page: 1, pageSize: 100 ,lang: "TR" };

    if (search.length > 2) {
      _params["search"] = search.toLowerCase();
      _params["searchFields"] = "name";
    }
    let attendeeGroups=await api.get("/rest/attendeeGroups/" + id);
    setDataModules(attendeeGroups.data.result)
    let restData =await api.get(`/rest/modules?${qs.stringify(_params)}`).then(({ data }) => {
    if (data.result) {
    setLoading(false)
   return data.result.rows.map((item,key)=>{
       
    item.isModulesData=false;
    if(attendeeGroups.data.result.modules){
      let attendeegroup=attendeeGroups.data.result.modules.find(e => e._id === item._id)
      let attendeegroups=attendeeGroups.data.result.modules.find(e => e.id === item._id)
        if(attendeegroup || attendeegroups){
          item.isModulesData=true;
        }           
       }
        item.key = key;
        return item;
    })
  }
  });
    setData(restData);
  }
  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
    }
  }, [])

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  }

  let attendeeGroupsUpdate = async (e) => {
    let json={
      id:e._id,
      name: e.name
    } 
    if (e.isModulesData===true)
    dataModules.modules = dataModules.modules.filter(g => g.id !== e._id)
    else
    dataModules.modules.push(json)

    api.put("/rest/attendeeGroups/" + dataModules._id, dataModules).then(({ data: { result, result_message } }) => {
      if (result_message.type === 'success') {
        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
        get();
      }
      else
        message.error(result_message.message, 2);
    })
  }

  let columns = [
 {
      title: 'Modül',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      dataIndex: 'modules',
      key: 'action',
      render: (modules, record) => {
        return (
          <Space size="small">
            <Button icon={record.isModulesData ? <MinusOutlined /> : <PlusOutlined />} onClick={() => attendeeGroupsUpdate(record)}  >
            </Button>
          </Space>
        )
      }
    }
  ];


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""} </h1>
          <p><font face="tahoma" size="3">{data ? data.name : ""} </font></p>
        </div>
        <div className="list-buttons">
          <div style={{ marginRight: "5px" }}>
          </div>
          <Link to={"/attendeeGroups"}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>

        </div>
      </div>

      <div className="table-wrap">
        <Search placeholder="Modül Ara" onChange={onChange} onSearch={(v) => { setSearch(v); get() }} />
        <Table dataSource={data} columns={columns} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "large" }}
          onChange={handleTableChange}
         />
      </div>

    </div>
  );
};

export default AttendeeGroupModules;
