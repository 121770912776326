import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  FileMarkdownOutlined,
  Loading3QuartersOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const FlightPlan = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  const { isSmall } = useSelector((state) => state.isSmall);
  let {languagesCms} = useSelector((state) => state.languagesCms);
  let { langCms } = useSelector((state) => state.langCms);
  let {langCode} = useSelector((state) => state.langCode);
  let { modules } = useSelector((state) => state.modules);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    module = modules && modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'type';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name,city,district,locality,phone,type';
    }
    let restData = await api
      .get(`/rest/flightPlan?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (module) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);


  let deleteRow = async (item_id) => {
    api.delete(`/rest/flightPlan/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let getReport = async () => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(`/rest/reports/flightPlan?lang=${lang}`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Uçuşlar.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: 'Tip',
      dataIndex: 'type',
      key: 'type',
      render(type) {
        if (type === 'DEPARTURE') {
          return 'GİDİŞ';
        }
        return 'DÖNÜŞ';
      },
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Uçuş Kodu',
      dataIndex: 'pnr',
      key: 'pnr',
      sorter: (a, b) => a.pnr - b.pnr,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kota',
      dataIndex: 'quota',
      key: 'quota',
      sorter: (a, b) => a.quota - b.quota,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kalkış',
      dataIndex: 'from',
      key: 'from',
      render(from) {
        return from.name;
      },
      sorter: (a, b) => a.from - b.from,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'İniş',
      dataIndex: 'to',
      key: 'to',
      render(to) {
        return to.name;
      },
      sorter: (a, b) => a.from - b.from,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/flight/plan/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Uçuşlar</h1>
        </div>
        <div className="list-buttons">
          <Button
            type="primary"
            icon={<FileMarkdownOutlined />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReport()}
          >
            {isSmall ? '' : languagesCms.EXPORT}
          </Button>
          <Link to="/flight">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {isSmall ? '' : 'Geri'}
            </Button>
          </Link>

          <Link to="/flight/plan/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          type="light"
          placeholder={'Ara...'}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default FlightPlan;
