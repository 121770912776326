import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import Parse from 'parse';
import {
  EditOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  PlusOutlined,
  CheckOutlined,
  LeftOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Plan = (props) => {
  let params = useParams();
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  const { isSmall } = useSelector((state) => state.isSmall);
  let { languagesCms } = useSelector((state) => state.languagesCms);

  let placeId = params.placeId !== 'add' ? params.placeId : false;
  let { customization } = useSelector((state) => state.customization);
  let parseServerURL = customization.parseServerURL;
  let parseAppId = customization.parseAppId;

  let { modules } = useSelector((state) => state.modules);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;
  let Plan = Parse.Object.extend('Plan');

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let query = new Parse.Query(Plan);
    query.limit(1000000);
    query.containedIn('placeId', [placeId]);

    if (search.length > 2) {
      query.fullText('title', search);
    }

    if (sorter) {
      if (!sorter.order) {
        sorter.order = 'order';
      }

      if (sorter.field) {
        sorter.order === 'descend'
          ? query.descending(sorter.field)
          : query.ascending(sorter.field);
      } else query.ascending('order');
    } else query.ascending('order');

    await query
      .find()
      .then(function (results) {
        setLoading(false);
        setTotalCount(results.length);
        results.map((item, key) => {
          let object = item;
          item._id = object.id;
          item.title = object.get('title');
          item.order = object.get('order');
          item.active = object.get('active');
          item.smokingArea = object.get('smokingArea');
          item.cafeteria = object.get('cafeteria');
          item.planImageUrl = object.get('planImageUrl');

          if (item.active) item.active = <CheckOutlined />;
          else item.active = <CloseOutlined />;

          if (item.smokingArea) item.smokingArea = <CheckOutlined />;
          else item.smokingArea = <CloseOutlined />;

          if (item.cafeteria) item.cafeteria = <CheckOutlined />;
          else item.cafeteria = <CloseOutlined />;

          item.key = key;
          return item;
        });

        setData(results);
      })
      .catch(function (error) {
        setData([]);
      });
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    let query = new Parse.Query(Plan);
    query.equalTo('objectId', item_id);
    let object = await query.first();

    object.destroy().then(
      (object) => {
        let newData = data.filter((el) => el._id !== item_id);
        setData(newData);
      },
      (error) => {
        message.error(languagesCms.ERROR_DELETE, 2);
      },
    );
  };
  let deleteSelectedRows = async () => {
    selectedRows.map(async (item) => {
      let query = new Parse.Query(Plan);
      query.equalTo('objectId', item._id);
      let object = await query.first();

      object.destroy().then(
        (object) => {
          let newData = data.filter((el) => el._id !== item._id);
          setData(newData);
        },
        (error) => {
          message.error('Bazı ayıtlar silinemedi', 2);
        },
      );
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: 'Plan',
      dataIndex: 'planImageUrl',
      key: 'planImageUrl',
      render: (text, record) => {
        return (
          <div style={{ width: '100px' }}>
            <img
              alt=""
              src={record.planImageUrl.url}
              style={{ width: '100%' }}
            />
          </div>
        );
      },
    },
    {
      title: languagesCms.TITLE,
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title - b.title,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sigara Alanı',
      dataIndex: 'smokingArea',
      key: 'smokingArea',
      sorter: (a, b) => a.smokingArea - b.smokingArea,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kafeterya',
      dataIndex: 'cafeteria',
      key: 'cafeteria',
      sorter: (a, b) => a.cafeteria - b.cafeteria,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link
            to={
              '/place/' + placeId + '/plan/edit/' + record._id + '/reservation'
            }
          >
            <Button icon={<SnippetsOutlined />}>
              {isSmall ? '' : 'Rezervasyon'}
            </Button>
          </Link>
          <Link
            to={
              '/place/' + placeId + '/plan/edit/' + record._id + '/coordinates'
            }
          >
            <Button icon={<SnippetsOutlined />}>
              {isSmall ? '' : 'Oturma Alanları'}
            </Button>
          </Link>
          <Link to={'/place/' + placeId + '/plan/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''} - Kat (Plan) İşlemleri</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to={'/place/' + placeId + '/plan/add'}>
            <Button
              type="light"
              icon={<PlusOutlined />}
              style={{ marginRight: '5px' }}
              size="large"
            >
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
          <Link to="/place">
            <Button
              type="light"
              icon={<LeftOutlined />}
              style={{ marginRight: '5px' }}
              size="large"
            >
              {isSmall ? '' : languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_TOPIC}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Plan;
