import React, { useState, useEffect, useMemo } from 'react';
import {
  Input,
  Form,
  Button,
  Select,
  List,
  Switch,
  Typography,
  Modal,
  Alert,
  InputNumber,
} from 'antd';
import { Pie } from 'react-chartjs-2';
import api from '../service/index';
import { nanoid } from 'nanoid';
import { FileInputSingle } from '../components';

let QuestionInput = (props) => {
  let { question, setQuestion, disabled } = props;
  let surveyId = props.surveyId;
  let [questionChart, setQuestionChart] = useState(null);
  let languagesCms = props.languagesCms;
  let setField = (field) => (value) => {
    setQuestion({
      ...question,
      [field]: value,
      freeLengthChoice: value,
    });
  };

  let setChoiceField = (i) => (field) => (value) => {
    setField('choices')(
      question.choices.map((c, _i) =>
        i === _i ? { ...c, [field]: value } : c,
      ),
    );
  };

  let addChoice = (choice) => {
    if (choice.media) {
      setField('choices')(
        question.choices.map((element) => {
          if (element.id === choice.id) {
            element.urlName = choice.media.id;
            element.media = choice.media;
            element.name = choice.name;
          }
          return element;
        }),
      );
    } else {
      setField('choices')([
        ...question.choices,
        {
          name: '',
          id: nanoid(),
          is_other: false,
          is_selected: false,
          other: '',
        },
      ]);
    }
  };

  let deleteChoice = (i) => {
    setField('choices')(question.choices.filter((c, _i) => _i !== i));
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        width: 750,
        padding: 10,
      }}
    >
      <Button
        style={{ float: 'right', zIndex: 10 }}
        type="danger"
        disabled={disabled}
        onClick={props.delete}
      >
        {languagesCms.DELETE_QUESTION}
      </Button>

      {question.type !== 'Free' &&
        question.type !== 'Media' &&
        question.childType !== 'Media' &&
        question.type !== 'TextToImage' && (
          <Button type="primary" onClick={() => setQuestionChart(question)}>
            {' '}
            Report{' '}
          </Button>
        )}

      <ChartModal
        record={questionChart}
        setRecord={setQuestionChart}
        surveyId={surveyId}
      />

      <Form.Item label={languagesCms.QUESTION_TYPE} required={true}>
        <Select
          value={question.type}
          onChange={setField('type')}
          disabled={disabled}
        >
          <Select.Option value="S">{languagesCms.SINGLE_TEXT} </Select.Option>
          <Select.Option value="M">{languagesCms.MULTI_TEXT} </Select.Option>
          <Select.Option value="F">{languagesCms.FREE_TEXT} </Select.Option>
          <Select.Option value="TextToImage">
            {languagesCms.TEXT_PICTURE}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Typography.Text strong>
          {languagesCms.REQUIRED_QUESTION}
        </Typography.Text>
        <Switch
          style={{ marginLeft: 5 }}
          checked={question.required}
          disabled={disabled}
          onChange={setField('required')}
        />
      </Form.Item>
      <Form.Item label={languagesCms.QUESTION_TEXT} required={true}>
        <Input.TextArea
          value={question.name}
          multiple={true}
          style={{ width: 700, height: 100 }}
          disabled={disabled}
          onChange={(e) => setField('name')(e.target.value)}
        />
      </Form.Item>
      {question.type === 'F' && (
        <Form.Item className="freeLengthChoice">
          <Typography.Text strong>{languagesCms.SELECT_LENGTH}</Typography.Text>
          <InputNumber
            value={
              question.freeLengthChoice === 'F'
                ? 1000
                : question.freeLengthChoice
            }
            onChange={setField('freeLengthChoice')}
            placeholder={languagesCms.MAX_CHARACTER}
            type="number"
          />
        </Form.Item>
      )}
      {question.type !== 'F' && (
        <>
          <div style={{ borderTop: '1px solid #eee', paddingTop: 10 }}>
            <Typography.Text strong>{languagesCms.OPTIONS}</Typography.Text>
            <Button
              style={{ float: 'right' }}
              onClick={addChoice}
              disabled={disabled}
            >
              {languagesCms.ADD_OPTION}
            </Button>
          </div>
          <List>
            {question.choices.map((c, i) => (
              <List.Item
                key={i}
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ marginRight: 5 }}>{i + 1}.</div>
                {question.type === 'TextToImage' && (
                  <div>
                    <FileInputSingle
                      languagesCms={languagesCms}
                      name="media"
                      title="Medyalar"
                      multi={true}
                      record={c}
                      setRecord={addChoice}
                    />
                    <Alert
                      type="info"
                      message="Kare görsel olmalıdır."
                      banner
                    />
                  </div>
                )}
                {question.type !== 'TextToImage' && (
                  <div>
                    <Input
                      style={{
                        width: i === question.choices.length - 1 ? 450 : 610,
                      }}
                      disabled={disabled}
                      value={c.name}
                      onChange={(e) =>
                        setChoiceField(i)('name')(e.target.value)
                      }
                    />
                  </div>
                )}
                {i === question.choices.length - 1 &&
                  question.type !== 'TextToImage' && (
                    <div style={{ marginLeft: 10 }}>
                      {languagesCms.FREE_TEXT}
                      <Switch
                        style={{ marginLeft: 5 }}
                        checked={c.is_other}
                        disabled={disabled}
                        onChange={(value) =>
                          setChoiceField(i)('is_other')(value)
                        }
                      />
                    </div>
                  )}
                <div style={{ marginLeft: 10 }}>
                  <Button
                    type="danger"
                    shape="circle-outline"
                    disabled={disabled}
                    onClick={() => deleteChoice(i)}
                  >
                    {languagesCms.DELETE}
                  </Button>
                </div>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </div>
  );
};

export default (props) => {
  let { record, setRecord, name, disabled } = props;
  let questions = record[name];
  let [page, setPage] = useState(0);
  let languagesCms = props.languagesCms;
  let addQuestion = () => {
    setRecord({
      ...record,
      questions: [
        ...(questions ? questions : []),
        {
          name: '',
          type: 'S',
          other: '',
          choices: [],
          is_answered: false,
          required: true,
        },
      ],
    });
    setPage(questions ? questions.length : 0);
  };

  return (
    <Form.Item>
      <div
        style={
          questions && questions.length > 0
            ? { width: 750 }
            : { display: 'flex' }
        }
      >
        {questions &&
          questions.map((q, i) => (
            <Button
              key={i}
              shape="circle"
              type={i === page ? 'primary' : 'dashed'}
              onClick={() => setPage(i)}
              style={{ marginRight: 5, marginBottom: 10 }}
            >
              {i + 1}
            </Button>
          ))}
        <Button
          type="primary"
          disabled={disabled}
          style={{ float: 'right' }}
          onClick={addQuestion}
        >
          {languagesCms.ADD_QUESTION}
        </Button>
      </div>

      {questions &&
        questions.map(
          (q, i) =>
            i === page && (
              <QuestionInput
                key={i}
                languagesCms={languagesCms}
                question={q}
                disabled={disabled}
                surveyId={record._id}
                setQuestion={(newQ) =>
                  setRecord({
                    ...record,
                    questions: questions.map((_q, _i) =>
                      _i === i ? newQ : _q,
                    ),
                  })
                }
                delete={() => {
                  setPage(page - 1);
                  setRecord({
                    ...record,
                    questions: questions.filter((_q, _i) => _i !== i),
                  });
                }}
              />
            ),
        )}
    </Form.Item>
  );
};

let ChartModal = (props) => {
  let { record, setRecord } = props;
  let surveyId = props.surveyId;

  let [chartData, setChartData] = useState(null);
  let [data, setData] = useState([]);
  let [options, setOptions] = useState({});

  useMemo(() => {
    if (record) {
      api
        .get(
          `/rest/surveyAnswers/chartData?id=${surveyId}&questionName=${record.name}`,
        )
        .then(({ data: { result, result_message } }) => {
          setData(result);
        });
    }
  }, [record]);

  useEffect(() => {
    let labels = [];
    let datasetsData = [];
    let total = 0;

    data.forEach((element) => {
      total += element.count;
    });

    data.forEach((element) => {
      labels.push(
        element._id +
          ' - ' +
          element.count.toString() +
          ' (%' +
          Math.floor((element.count / total) * 100 + 0.5).toString() +
          ')',
      );
      datasetsData.push(element.count);
    });

    setChartData({
      chartData: {
        labels: labels,
        datasets: [
          {
            data: datasetsData,
            backgroundColor: [
              'rgba(0, 0, 255, 0.6)',
              'rgba(255, 64, 64, 0.6)',
              'rgba(127, 25,5 0, 0.6)',
              'rgba(255, 185, 15, 0.6)',
              'rgba(153, 102, 255, 0.6)',

              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(139, 0, 139, 0.6)',
              'rgba(255, 140, 0, 0.6)',
              'rgba(153, 50, 204, 0.6)',

              'rgba(143, 188, 143, 0.6)',
              'rgba(121, 205, 205, 0.6)',
              'rgba(255, 20, 147, 0.6)',
              'rgba(255, 48, 48, 0.6)',
              'rgba(34, 139, 34, 0.6)',

              'rgba(139, 117, 0, 0.6)',
              'rgba(130, 130, 130, 0.6)',
              'rgba(255, 105, 180, 0.6)',
              'rgba(255, 246, 143, 0.6)',
              'rgba(154, 192, 205, 0.6)',
            ],
          },
        ],
      },
    });

    setOptions({
      options: {
        responsive: true,
        legend: {
          position: 'bottom',
        },
        title: {
          display: false,
          text: 'Chart.js Doughnut Chart',
        },
        labels: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce(function (
                previousValue,
                currentValue,
                currentIndex,
                array,
              ) {
                return previousValue + currentValue;
              });
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor((currentValue / total) * 100 + 0.5);
              return percentage + '%';
            },
          },
        },
      },
    });
  }, [data]);

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    document.title = iframe.getAttribute('title');
    const iframeWindow = iframe.contentWindow || iframe;
    iframe.focus();
    iframeWindow.print();

    return false;
  };

  return (
    record && (
      <div>
        <iframe
          id="printSurvey"
          src={'/SurveyDetailPrint/' + surveyId + '/question/' + record.name}
          title={record.name}
          style={{ position: 'absolute', zIndex: '-1' }}
          width={window.innerWidth <= 1024 ? '100%' : '50%'}
        />
        <Modal
          title={record === null ? '' : record.name}
          visible={record === null ? false : true}
          onOk={() => setRecord(null)}
          onCancel={() => setRecord(null)}
          footer={[
            <Button key="print" onClick={() => printIframe('printSurvey')}>
              İndir
              {/* {languagesCms.PRINT} */}
            </Button>,
            <Button key="submit" type="primary" onClick={() => setRecord(null)}>
              OK
            </Button>,
          ]}
        >
          <ChartJS chartData={chartData} options={options}></ChartJS>
        </Modal>
      </div>
    )
  );
};

let ChartJS = (props) => {
  let { chartData } = props.chartData;
  let { options } = props.options;
  return (
    <div className="chart">
      <Pie data={chartData} options={options} />
    </div>
  );
};
