import React, { useState, useEffect } from 'react';
import { Upload, message, Progress } from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import api from '../service/index';
import { Button } from 'antd';

export default (props) => {
  let { record, setRecord, name, disabled, ext } = props;
  let [, setFile] = useState([]);
  let [imageUrl, setImageUrl] = useState(false);
  let [loading, setLoading] = useState(false);
  let [uploadStart, setUploadStart] = useState(false);
  const [progress, setProgress] = useState(0);
  let languagesCms = props.languagesCms;
  useEffect(() => {
    if (record[name]) {
      setImageUrl(record[name].url);
    }
  }, []);

  let handleChange = (info) => {
    if (!info.event && uploadStart === false) {
      setUploadStart(true);
      const formData = new FormData();
      formData.append('files_0', info.file.originFileObj);
      if (info.file.originFileObj.size > 56844918)
        message.warning(languagesCms.WARNING_FILE_SIZE);
      else {
        api
          .post('/rest/upload', formData, {
            headers: { ContenType: 'multipart/form-data' },
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setProgress(percent - 1);
            },
          })
          .then(({ data: { result, result_message } }) => {
            setTimeout(() => setProgress(100), 1000);
            setTimeout(() => setProgress(0), 2000);
            if (result_message.type === 'success') {
              delete result[0].width;
              delete result[0].height;
              setFile(result[0]);
              setImageUrl(result[0].url);
              setRecord({ ...record, [name]: result[0] });
              setUploadStart(false);
            }
            setLoading(false);
          });
      }
    }
  };

  let beforeUpload = () => {
    setImageUrl(false);
    setLoading(true);
  };
  let remove = () => {
    setFile([]);
    setImageUrl(false);
    setRecord({ ...record, [name]: null });
  };
  const uploadButton = (
    <div
      icon={
        loading ? (
          <LoadingOutlined onClick={() => handleChange()} />
        ) : (
          <PlusOutlined />
        )
      }
    >
      <PlusOutlined /> Upload
    </div>
  );

  return (
    <>
      <Upload
        name={name}
        accept={ext}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        disabled={disabled}
      >
        {imageUrl ? (
          <div>
            <ReloadOutlined style={{ fontSize: '2rem' }} />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
      {imageUrl ? (
        <div>
          <div>
            <audio
              controls
              style={{ objectFit: 'cover', width: 360 }}
              alt=""
              src={imageUrl}
            />
          </div>
          <div style={{ marginLeft: 5 }}>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              shape="round"
              size="medium"
              onClick={() => remove()}
              style={{ width: 350 }}
            >
              {languagesCms.DELETE}
            </Button>
          </div>
        </div>
      ) : null}
      {progress > 0 ? (
        <Progress style={{ width: '60%' }} percent={progress} />
      ) : null}
    </>
  );
};
