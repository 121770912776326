import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Input } from 'antd';
import api from '../service/index';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const PhotoAlbumSubCategoryList = (props) => {
  const { Search } = Input;
  let params = useParams();
  let parent_id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  const { isSmall } = useSelector((state) => state.isSmall);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let { modules } = useSelector((state) => state.modules);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    module = modules && modules.find((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let query = [];
    query['parent'] = parent_id;

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'title';
    }

    console.log(parent_id);
    let restData = await api
      .get(`/rest/photoAlbumCategories?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (module) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/photoAlbumCategories/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };
  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.PHOTO,
      dataIndex: 'imageurl',
      key: 'imageurl',
      render: (text, record) => {
        return (
          <div style={{ width: '100px' }}>
            <img src={text} style={{ width: '100%' }} alt="" />
          </div>
        );
      },
    },
    {
      title: languagesCms.TITLE,
      dataIndex: 'langs',
      key: 'langs',
      render: (langs) =>
        langs.map((l, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {l.title} - {l.lang}
          </div>
        )),
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (groups) =>
        groups.length > 0 &&
        groups.map((e) => <div style={{ marginBottom: 5 }}>{e.name}</div>),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {record.type === 'category' && (
            <Link to={'/photoAlbumSubCategory/list/' + record._id}>
              <Button icon={<SnippetsOutlined />}>
                {isSmall ? '' : 'Sub List'}
              </Button>
            </Link>
          )}
          {record.type === 'category' && (
            <Link to={'/photoAlbumSubCategory/detail/' + record._id + '/add'}>
              <Button type="light" icon={<PlusOutlined />} size="large">
                {isSmall ? '' : 'sub Category Ekle'}
              </Button>
            </Link>
          )}

          {record._id !== 'postwall' && (
            <Link to={'/photoAlbum/detail/' + record._id}>
              <Button icon={<SnippetsOutlined />}>
                {isSmall ? '' : languagesCms.DETAIL}
              </Button>
            </Link>
          )}

          <Link to={'/photoAlbum/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to={'/photoAlbumSubCategory/detail/' + parent_id + '/add'}>
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : 'sub Category Ekle'}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_ALBUM}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default PhotoAlbumSubCategoryList;
