import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    logoEN: {},
};

const logoEN = createSlice({
  name: 'logoEN',
  initialState,
  reducers: {
    setLogoEN: (state, action) => {
      state.logoEN  = action.payload;
    },
  },
});

export const { setLogoEN } = logoEN.actions;
export default logoEN.reducer;
