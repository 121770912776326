import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import 'react-quill/dist/quill.snow.css';
import './styles/style.css';

import store from './stores';
import trTR from 'antd/lib/locale/tr_TR';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import App from './App';

import * as serviceWorker from './serviceWorker';



ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={trTR}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);


serviceWorker.unregister();
