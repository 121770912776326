import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Card,
  message,
  Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { EducationsPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const LessonDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;

  let newRecord = {
    name: '',
    startDate: new Date(),
    endDate: new Date(),
  };
  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let { modules } = useSelector((state) => state.modules);
  let { languagesCms } = useSelector((state) => state.languagesCms);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/lessons/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.code === null) errors.code = languagesCms.ENFORCED;

    if (data.classRoom === null) errors.classRoom = languagesCms.ENFORCED;

    if (data.educationId === null) errors.educationId = languagesCms.ENFORCED;

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.state === null) errors.state = languagesCms.ENFORCED;

    if (data.startDate === null) errors.startDate = languagesCms.ENFORCED;

    if (data.endDate === null) errors.endDate = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/lessons/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/lessons');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/lessons', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/lessons');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/lessons">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Ders Kodu"
                  required
                  help={errors.code}
                  validateStatus={errors.code ? 'error' : 'success'}
                >
                  <Input
                    name="code"
                    value={data.code}
                    onChange={(e) => setData({ ...data, code: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Sınıf"
                  required
                  help={errors.classRoom}
                  validateStatus={errors.classRoom ? 'error' : 'success'}
                >
                  <Input
                    name="classRoom"
                    value={data.classRoom}
                    onChange={(e) =>
                      setData({ ...data, classRoom: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Ders"
                  required
                  help={errors.educationId}
                  validateStatus={errors.educationId ? 'error' : 'success'}
                >
                  <EducationsPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="educationId"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TYPE}
                  required
                  help={errors.state}
                  validateStatus={errors.state ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={
                      data.state ? data.state : 'Ders Durumunu Seçiniz'
                    }
                    style={{ width: 250 }}
                    onChange={(v) => {
                      setData({ ...data, state: v });
                    }}
                  >
                    <Select.Option value="waiting">Bekleme</Select.Option>
                    <Select.Option value="rollcall">Yoklama Al</Select.Option>
                    <Select.Option value="completed">Dersi Bitir</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.START_DATE}
                  required
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.startDate)}
                    onChange={(v) => setData({ ...data, startDate: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.END_DATE}
                  required
                  help={errors.endDate}
                  validateStatus={errors.endDate ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.endDate)}
                    onChange={(v) => setData({ ...data, endDate: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default LessonDetail;
