import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginSetting: {},
};

const loginSetting = createSlice({
  name: 'loginSetting',
  initialState,
  reducers: {
    setLoginSetting: (state, action) => {
      state.loginSetting = action.payload;
    },
  },
});

export const { setLoginSetting } = loginSetting.actions;
export default loginSetting.reducer;
